import React, { useRef, useState } from 'react';
import {
  Tr,
  Th,
  Td,
  Box,
  Text,
  Input,
  Table,
  Tbody,
  Thead,
  SimpleGrid,
  IconButton,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import { ArrowBackIcon } from '@chakra-ui/icons';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';

export default function ViewPurchases() {
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);
  const uWarehouse = get(getMe, 'uWarehouse', '');

  const errorRef = useRef();
  const successRef = useRef();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const documentLines = get(data, 'documentLines', []);
  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(documentLines);

  const accept = () => {
    setIsLoading(true);
    const stockTransferLines = mainData.map((v, i) => {
      return {
        lineNum: get(v, 'lineNum', ''),
        itemCode: get(v, 'itemCode', ''),
        itemDescription: get(v, 'itemDescription', ''),
        quantity: Number(get(v, 'writtenQuant', '')),
        unitPrice: get(v, 'price', ''),
      };
    });

    let allData = {
      fromWarehouse: '01',
      toWarehouse: uWarehouse,
      stockTransferLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`stock-transfers/directly-add`, allData, header)
      .then((res) => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const changeQuant = (v, i) => {
    let changedData = [...mainData];
    changedData[i].writtenQuant = v;
    setMainData(changedData);
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <SimpleGrid
            columns={{ base: 1, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {t('Поставщик')}
                  {':'}
                </Text>
                <Text>{get(data, 'cardName', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {t('Дата создания')}
                  {':'}
                </Text>
                <Text>{time.to(get(data, 'docDate', ''), 'DD-MM-YYYY')}</Text>
              </Box>
            </Box>
          </SimpleGrid>
          <Table variant={'unstyled'} color="gray.500">
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  width={'50px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {'№'}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Наименование товара')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Кол-во')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mainData.map((v, i) => {
                return (
                  <Tr key={i}>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'lineNum', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'itemDescription', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      <Input
                        color={tablBody}
                        type={'number'}
                        value={get(v, 'writtenQuant', '')}
                        onChange={(v) => changeQuant(v.target.value, i)}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>

          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <Button isLoading={isLoading} width={200} onClick={accept}>
              {t('Принят')}
            </Button>
          </Box>
        </Card>
      </Box>
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r;
        }}
      />
      <SuccessModal
        getRef={(r) => {
          successRef.current = r;
        }}
      />
    </Style>
  );
}
