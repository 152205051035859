import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddIcon, ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import SuccessModal from 'components/Modals/SuccessModal';
import ProductModal from 'components/Modals/ProductModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';

export default function ViewsalesTelegramSHOP() {
  const { t } = useTranslation();

  const { token, getMe } = useSelector((state) => state.main);
  const uWarehouse = get(getMe, 'uWarehouse', 'uWarehouse');

  const errorRef = useRef();
  const successRef = useRef();
  const productRef = useRef();

  const navigation = useNavigate();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnTextColor = useColorModeValue('black', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const documentLines = get(data, 'documentLines', []);
  const docEntry = get(data, 'docEntry', '');

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(documentLines);
  const docTotal = mainData?.reduce(
    (acc, line) => acc + line.price * line.quantity,
    0,
  );
  const createNewRequest = () => {
    setIsLoading(true);

    const documentLines = mainData.map((v, i) => {
      return {
        baseType: 23,
        baseEntry: get(data, 'docEntry', ''),
        baseLine: get(v, 'lineNum', ''),
      };
    });

    let allData = {
      cardCode: get(data, 'cardCode', ''),
      docDate: new Date().toISOString(),
      docDueDate: new Date().toISOString(),
      bot: 'Ha',
      documentLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`orders/quotation-to-order`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const editItem = () => {
    setIsLoading(true);
    const documentLines = mainData.map((v, i) => {
      return {
        lineNum: get(v, 'lineNum', 0),
        itemCode: get(v, 'itemCode', ''),
        itemDescription: get(v, 'itemDescription', ''),
        quantity: Number(get(v, 'quantity', '')),
        unitPrice: Number(get(v, 'unitPrice', '')),
        warehouseCode: uWarehouse,
      };
    });

    let allData = {
      cardCode: get(data, 'cardCode', ''),
      cardName: get(data, 'cardName', ''),
      documentLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .patch(`sales-quotations/${docEntry}`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const addItem = (item) => {
    setMainData((pr) => [
      ...pr,
      {
        lineNum: mainData.length,
        itemCode: get(item, 'itemCode', ''),
        itemDescription: get(item, 'itemName', ''),
        quantity: '',
        unitPrice: get(item, 'price', ''),
        isEdited: true,
      },
    ]);
  };

  const changeQuant = (i, quant) => {
    let changedData = [...mainData];
    changedData[i].quantity = quant;
    changedData[i].isEdited = true;
    setMainData(changedData);
  };

  const changePrice = (i, price_item) => {
    let changedData = [...mainData];
    changedData[i].unitPrice = price_item;
    changedData[i].isEdited = true;
    setMainData(changedData);
  };

  const remove = (i) => {
    let changedData = [...mainData];
    changedData.splice(i, 1);
    changedData[i].isEdited = true;
    setMainData(changedData);
  };

  console.log(data);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={get(data, 'docNum', '')}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={get(data, 'cardName', '')}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={time.to(get(data, 'docDate', ''), 'DD-MM-YYYY')}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Flex gap={'10px'}>
              <Text color={tablBody} textAlign={'center'}>
                {t('docTotal')}
              </Text>
              <Input
                placeholder={`${t('docTotal')}`}
                value={docTotal ? new Intl.NumberFormat().format(docTotal) : 0}
                borderRadius={'15px'}
                color={btnTextColor}
                disabled={true}
              />
            </Flex>
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    width={'50px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    {t('Del')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Narxi')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        width={'50px'}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        textAlign={'center'}
                      >
                        <IconButton
                          onClick={() => remove(index)}
                          _hover={false}
                          backgroundColor={'red'}
                          icon={
                            <DeleteIcon color={'white'} w="13px" h="13px" />
                          }
                        />
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemDescription', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'quantity', '')}
                          onChange={(title) =>
                            changeQuant(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'unitPrice', '')}
                          onChange={(title) =>
                            changePrice(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          <Box justifyContent={'space-between'} display={'flex'} mt={'20px'}>
            <IconButton
              onClick={() => productRef.current?.open()}
              _hover={false}
              backgroundColor={'brand.200'}
              icon={<AddIcon color={'white'} w="10px" h="10px" />}
            />

            <Box display={'flex'} alignItems={'center'}>
              <Button
                me={'10px'}
                isLoading={isLoading}
                onClick={editItem}
                width={200}
              >
                {t('Ozgartirish')}
              </Button>
              <Button
                isLoading={isLoading}
                onClick={createNewRequest}
                width={200}
                backgroundColor="green.500"
              >
                {t('Создать Заказ на продажу')}
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      <ProductModal
        getRef={(r) => (productRef.current = r)}
        onConfirm={(item) => addItem(item)}
      />
    </>
  );
}
