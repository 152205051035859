import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Switch,
  Text,
  useColorModeValue,
  Input,
} from '@chakra-ui/react';
import api from 'api';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from 'components/Button';
import { get } from 'lodash';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Style from './Style';
import BussinesPartnerModal from 'components/Modals/BussinesPartnerModal';
import RegionsModal from 'components/Modals/RegionsModal';
import PriceListModal from '../PriceListModal';

const CreateClientsModal = ({ getRef, onConfirm = () => {} }) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [userPhone, setUserPhone] = useState('+998');
  const [userName, setUserName] = useState('');
  const [wData, setwData] = useState({
    code: '',
    name: t('Biznes hamkor tanlang'),
  });
  const [region, setRegion] = useState({
    id: '',
    name: t('Manzilni tanlang'),
  });
  const [priceData, setPriceData] = useState({
    priceListNumber: '',
    priceListName: t('Narx royxati'),
  });

  const errorRef = useRef();
  const successRef = useRef();
  const regionsModal = useRef();
  const bussinessPartnerRef = useRef();
  const priceListRef = useRef();

  const btnColor = useColorModeValue('gray.300', 'gray.500');
  const btnTextColor = useColorModeValue('black', 'white');

  const { token } = useSelector((state) => state.main);

  useEffect(() => {
    const ref = {
      open: () => {
        setIsOpen(true);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const createNewRequest = () => {
    setIsLoading(true);

    let allData = {
      cardName: userName,
      groupCode: get(wData, 'code', ''),
      phone1: userPhone,
      region: get(region, 'name', ''),
      valid: isActive ? 'tYES' : 'tNO',
      priceListNum: get(priceData, 'priceListNumber', ''),
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`business-partners`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto" width="90%" maxW={900}>
        <ModalHeader>{t('Mijoz yarating')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Style>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
              gap="20px"
              mb="20px"
            >
              <Button
                onClick={() => bussinessPartnerRef.current?.open()}
                minW={'100%'}
                backgroundColor={btnColor}
                color={btnTextColor}
              >
                {get(wData, 'name', '')}
              </Button>
              <Button
                onClick={() => regionsModal.current?.open()}
                minW={'100%'}
                backgroundColor={btnColor}
                color={btnTextColor}
              >
                {get(region, 'name', '')}
              </Button>
              <Button
                onClick={() => priceListRef.current?.open()}
                minW={'100%'}
                backgroundColor={btnColor}
                color={btnTextColor}
              >
                {get(priceData, 'priceListName', '')}
              </Button>
              <Input
                placeholder={`${t('Ism kiriting')}`}
                value={userName}
                onChange={(v) => setUserName(v.target.value)}
                borderRadius={'15px'}
                color={btnTextColor}
              />
              <Input
                value={userPhone}
                onChange={(v) => setUserPhone(v.target.value)}
                borderRadius={'15px'}
                color={btnTextColor}
              />
              <Box display={'flex'} alignItems={'center'}>
                <Text me={'10px'}>{t('Aktiv')}</Text>
                <Switch
                  colorScheme={'green'}
                  onChange={(v) => {
                    setIsActive(v.target.checked);
                  }}
                  isChecked={isActive}
                />
              </Box>
            </SimpleGrid>
            <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
              <Button
                isLoading={isLoading}
                onClick={createNewRequest}
                width={250}
              >
                {t('Yaratish')}
              </Button>
            </Box>

            <>
              <ErrorModal getRef={(r) => (errorRef.current = r)} />
              <SuccessModal
                getRef={(r) => (successRef.current = r)}
                isBack={false}
              />
              <BussinesPartnerModal
                getRef={(r) => (bussinessPartnerRef.current = r)}
                onConfirm={(item) => setwData(item)}
              />
              <RegionsModal
                getRef={(r) => (regionsModal.current = r)}
                onConfirm={(item) => setRegion(item)}
              />
              <PriceListModal
                getRef={(r) => (priceListRef.current = r)}
                onConfirm={(item) => setPriceData(item)}
              />
            </>
          </Style>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CreateClientsModal;
