import React, { useEffect, useState } from 'react';
import { Box, Icon, Text, SimpleGrid } from '@chakra-ui/react';
import MiniStatistics from 'components/card/MiniStatistics';
import { HiCurrencyDollar } from 'react-icons/hi2';
import { FaMoneyBill, FaWarehouse } from 'react-icons/fa6';
import { FaQrcode } from 'react-icons/fa';
import IconBox from 'components/icons/IconBox';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';

export default function KassaSHOP() {
  const { t } = useTranslation();

  const { token, getMe } = useSelector((state) => state.main);
  const uAccount = get(getMe, 'uAccount', '');
  const uDollarAccount = get(getMe, 'uDollarAccount', '');

  const fetchOrders = async () => {
    const response = await api.get(
      `chart-of-accounts/dollar-account/${uDollarAccount}/sum-account/${uAccount}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    'kassaSHOP',
    () => fetchOrders(),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  // console.log(mainData);

  if (isError) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
              {t('Malumotlarni yuklab olishni iloji bolmadi')}
            </Text>
          </Card>
        </Box>
      </Style>
    );
  }

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Text
          fontSize={'30px'}
          fontWeight={'500'}
          mb={'15px'}
          textAlign={'center'}
        >
          {t('Birinchi kassa')}
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={HiCurrencyDollar}
                    color={'white'}
                  />
                }
              />
            }
            name={`${t('Valyuta')}`}
            value={
              get(mainData, '[0].acctCurrency', '') === 'USD' ||
              get(mainData, '[0].acctCurrency', '') === 'UZS'
                ? get(mainData, '[0].acctCurrency', '')
                : `${t('USD va UZS')}`
            }
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={
                  <Icon w="32px" h="32px" as={FaMoneyBill} color={'white'} />
                }
              />
            }
            name={`${t('Balans')}`}
            value={get(mainData, '[0].balance', '')}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={<Icon w="28px" h="28px" as={FaQrcode} color="white" />}
              />
            }
            name={`${t('Kod')}`}
            value={get(mainData, '[0].code', '')}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={<Icon w="28px" h="28px" as={FaWarehouse} color="white" />}
              />
            }
            name={`${t('Ombor nomi')}`}
            value={get(mainData, '[0].name', '')}
          />
        </SimpleGrid>
        <Text
          fontSize={'30px'}
          fontWeight={'500'}
          mb={'15px'}
          textAlign={'center'}
        >
          {t('Ikkinchi kassa')}
        </Text>
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
          gap="20px"
          mb="20px"
        >
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={
                  <Icon
                    w="32px"
                    h="32px"
                    as={HiCurrencyDollar}
                    color={'white'}
                  />
                }
              />
            }
            name={`${t('Valyuta')}`}
            value={
              get(mainData, '[1].acctCurrency', '') === 'USD' ||
              get(mainData, '[1].acctCurrency', '') === 'UZS'
                ? get(mainData, '[1].acctCurrency', '')
                : `${t('USD va UZS')}`
            }
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={
                  <Icon w="32px" h="32px" as={FaMoneyBill} color={'white'} />
                }
              />
            }
            name={`${t('Balans')}`}
            value={get(mainData, '[1].balance', '')}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={<Icon w="28px" h="28px" as={FaQrcode} color="white" />}
              />
            }
            name={`${t('Kod')}`}
            value={get(mainData, '[1].code', '')}
          />
          <MiniStatistics
            startContent={
              <IconBox
                w="56px"
                h="56px"
                bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                icon={<Icon w="28px" h="28px" as={FaWarehouse} color="white" />}
              />
            }
            name={`${t('Ombor nomi')}`}
            value={get(mainData, '[1].name', '')}
          />
        </SimpleGrid>
      </Box>
    </Style>
  );
}
