import { Button } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

const Btn = ({
  isLoading = false,
  isDisable = false,
  width = '150px',
  backgroundColor = 'brand.300',
  loadingText = 'Yuklanmoqda',
  color = 'brand.100',
  children = '',
  onClick = () => {},
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Button
      isLoading={isLoading}
      loadingText={t(loadingText)}
      aria-disabled={isLoading || isDisable}
      minW={width}
      backgroundColor={backgroundColor}
      color={color}
      _hover={false}
      p={0}
      px={'20px'}
      mb={'3px'}
      onClick={onClick}
      isDisabled={isLoading || isDisable}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default Btn;
