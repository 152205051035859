import styled from 'styled-components';

const Style = styled.div`
  .iconCard {
    display: flex;
    align-items: center;
  }
  .text {
    margin-left: 20px;
  }
  .mainTabel {
    .thead {
      tr {
        th {
          text-align: start;
          border: none;
          font-size: 12px;
        }
        .marginB {
          padding-bottom: 10px;
        }
        .firstTh {
          padding: 13px 15px;
          border-radius: 17px 0 0 17px;
          border: none;
          text-align: center;
        }
        .firstTh2 {
          padding: 13px 15px;
        }
        .firstTh3 {
          padding: 13px 15px;
          border-radius: 0 17px 17px 0;
        }
      }
    }
    tbody {
      tr {
        .marginB {
          padding-bottom: 10px;
        }
        margin-bottom: 10px !important;
        td {
          text-align: start;
          border: none;
          font-size: 13px;
        }
        .firstTd {
          padding: 10px 15px;
          border-radius: 17px 0 0 17px;
          border: none;
          font-weight: 700;
          text-align: center;
        }
        .firstTd2 {
          padding: 10px 15px;
        }
        .firstTd3 {
          padding: 10px 15px;
          border-radius: 0 17px 17px 0;
        }
      }
    }
  }
  .redcard {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: red;
  }
  .greenCard {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: green;
  }
`;

export default Style;
