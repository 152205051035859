import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const SuccessModal = ({ getRef, isBack = true, onConfirm }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const ref = {
      open: () => {
        setIsOpen(true);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const onClose = () => {
    setIsOpen(false);
    if (isBack) {
      navigate(-1);
    } else if (onConfirm) {
      onConfirm();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto">
        <ModalHeader>{t('Muvaffaqiyatli')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>
            {t('Amaliyot muvaffaqiyatli amalga oshdi')}
            {'!'}
          </Text>
        </ModalBody>

        <ModalFooter>
          <Button
            backgroundColor="green.500"
            color={'white'}
            mr={3}
            onClick={onClose}
          >
            {t('Tushunarli')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SuccessModal;
