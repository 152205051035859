import React, { useEffect, useState } from 'react';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import Style from './Style';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import api from 'api';
import { useQuery } from 'react-query';

export default function ViewcompletionOfSale() {
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const blue = useColorModeValue('lightBlue.50', 'lightBlue.700');
  const gray = useColorModeValue('gray.200', 'gray.700');

  const location = useLocation();
  const mainD = get(location, 'state', {});
  const docEntry = get(mainD, 'docEntry', '');
  const docDate = get(mainD, 'docDate', '');

  const [mainData, setMainData] = useState([]);

  const fetchOrders = async () => {
    const response = await api.get(`invoices/${docEntry}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isFetching, isError } = useQuery('orders', fetchOrders, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data !== undefined) {
      let miniData = get(JSON.parse(data).data, 'documentLines', []);
      let filteredData = miniData.filter((v) => {
        return get(v, 'warehouseCode', '') === get(getMe, 'uWarehouse', '');
      });
      setMainData(filteredData);
    }
  }, [data]);

  if (isFetching) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <IconButton
            onClick={() => navigation(-1)}
            backgroundColor={'brand.200'}
            icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
            mb={'5px'}
          />
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <SimpleGrid
              columns={{ base: 1, lg: 2, '2xl': 6 }}
              gap="20px"
              mb="20px"
            >
              <Box>
                <Box display={'flex'}>
                  <Text color={tableHead}>
                    {t('Поставщик')}
                    {' : '}
                  </Text>
                  <Text>{get(mainD, 'cardName', '')}</Text>
                </Box>
                <Box display={'flex'}>
                  <Text color={tableHead}>
                    {t('Дата создания')}
                    {' : '}
                  </Text>
                  <Text>{`${docDate.slice(6)}-${docDate.slice(
                    4,
                    6,
                  )}-${docDate.slice(0, 4)}`}</Text>
                </Box>
              </Box>
              <Box>
                <Box display={'flex'}>
                  <Text color={tableHead}>
                    {t('Всего')}
                    {' : '}
                  </Text>
                  <Text>
                    {get(mainD, 'docTotal', '')} {get(mainD, 'docCurrency', '')}
                  </Text>
                </Box>
              </Box>
            </SimpleGrid>
            <CircularProgress
              alignSelf={'center'}
              isIndeterminate
              color="brand.300"
            />
          </Card>
        </Box>
      </Style>
    );
  }

  if (isError) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <IconButton
            onClick={() => navigation(-1)}
            backgroundColor={'brand.200'}
            icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
            mb={'5px'}
          />
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
              {t('Malumotlarni yuklab olishni iloji bolmadi')}
            </Text>
          </Card>
        </Box>
      </Style>
    );
  }

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <SimpleGrid
            columns={{ base: 1, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Поставщик')}
                  {' : '}
                </Text>
                <Text>{get(mainD, 'cardName', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Дата создания')}
                  {' : '}
                </Text>
                <Text>{`${docDate.slice(6)}-${docDate.slice(
                  4,
                  6,
                )}-${docDate.slice(0, 4)}`}</Text>
              </Box>
            </Box>
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Всего')}
                  {' : '}
                </Text>
                <Text>
                  {get(mainD, 'docTotal', '')} {get(mainD, 'docCurrency', '')}
                </Text>
              </Box>
            </Box>
          </SimpleGrid>
          <Table variant={'unstyled'} color="gray.500">
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {'№'}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Наименование товара')}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Кол-во')}
                </Th>

                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('№ Склада')}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Belgilash')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mainData.map((v, i) => {
                return (
                  <Tr
                    key={i}
                    backgroundColor={
                      get(getMe, 'uWarehouse', '') ===
                      get(v, 'warehouseCode', '')
                        ? blue
                        : gray
                    }
                  >
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'lineNum', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'itemDescription', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'quantity', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'warehouseCode', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Checkbox
                          borderColor={'brand.200'}
                          disabled={true}
                          defaultChecked={get(v, 'isChecked', '') !== 'no'}
                        />
                      </Box>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Card>
      </Box>
    </Style>
  );
}
