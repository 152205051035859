import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { ChakraProvider } from '@chakra-ui/react';
import { Routes, Route } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import SignInCentered from 'views/auth/signIn';
import { store, persistor } from './store';
import AdminLayout from './layouts/admin';
import initialTheme from './theme/theme';
import { Provider } from 'react-redux';
import { useState } from 'react';
import './assets/css/App.css';
import i18n from './i18n';

export default function Main() {
  const [currentTheme, setCurrentTheme] = useState(initialTheme);
  return (
    <I18nextProvider i18n={i18n()}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ChakraProvider theme={currentTheme}>
            <Routes>
              <Route path="/" element={<SignInCentered />} />
              <Route
                path="admin/*"
                element={
                  <AdminLayout
                    theme={currentTheme}
                    setTheme={setCurrentTheme}
                  />
                }
              />
            </Routes>
          </ChakraProvider>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  );
}
