import React from 'react';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';

export function SidebarBrand() {
  const tableHead = useColorModeValue('brand.500', 'white');

  return (
    <Flex align="center" direction="column">
      <Text fontSize={'4xl'} fontWeight="bold" color={tableHead}>
        E'zoz
      </Text>
    </Flex>
  );
}

export default SidebarBrand;
