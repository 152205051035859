import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import ClientsModal from '../ClientsModal';
import time from '../../../helpers/time';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import api from 'api';

const AddIncomingPayment = ({
  getRef,
  isClientShow = false,
  isTypesShow = false,
  isDocTotalaShow = false,
  isShowDate = true,
  onConfirm = () => {},
}) => {
  const navigate = useNavigate();
  const { getMe, token } = useSelector((state) => state.main);
  const { t } = useTranslation();
  const clientsRef = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState('');
  const [docTotla, setDocTotla] = useState('');
  const [docCurrency, setDocCurrency] = useState('');
  const [currency, setCurrency] = useState('1');
  const [summa, setSumma] = useState('');
  const [valuta, setValuta] = useState('USD');
  const [currentDate, setCurrentDate] = useState(time.current('YYYY-MM-DD'));
  const [course, setCourse] = useState('');
  const [disabledCourse, setDisabledCourse] = useState(false);

  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnDefault = useColorModeValue('gray.200', 'gray.500');
  const textDefault = useColorModeValue('black', 'White');

  useEffect(() => {
    const ref = {
      open: (data) => {
        setIsOpen(true);
        setDocTotla(get(data, 'docTotal', ''));
        setDocCurrency(get(data, 'currency', ''));
        console.log('data', data);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const fetchOrders = async ({ queryKey }) => {
    const [_, date] = queryKey;

    let url = `currency-rates/by-date`;

    const response = await api.post(
      url,
      {
        currency: 'UZS',
        date: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['getCurrentRate', time.current('YYYY-MM-DD')],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setCourse(resData);
    }
  }, [data]);

  const onClose = () => {
    setIsOpen(false);
  };

  const typeOfPaymentData = [
    {
      title: t('Naqd'),
      val: '1',
    },
    {
      title: t('Karta'),
      val: get(getMe, 'uCartAccount', ''),
    },
    {
      title: t('Terminal'),
      val: get(getMe, 'uBankAccount', ''),
    },
  ];

  const typeOfValuta = [
    {
      title: t('Dollar'),
      val: 'USD',
    },
    {
      title: t('Som'),
      val: 'UZS',
    },
  ];

  const accept = () => {
    setIsOpen(false);
    const currencyPost =
      currency === '1' && valuta === 'USD'
        ? get(getMe, 'uDollarAccount', '')
        : currency === '1' && valuta === 'UZS'
        ? get(getMe, 'uAccount', '')
        : currency;
    onConfirm(currentDate, user, currencyPost, summa, valuta, course);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto">
        <ModalHeader>{t('Tolov amalga oshiring')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isClientShow ? (
            <Button
              width="100%"
              mb={'20px'}
              onClick={() => clientsRef.current?.open()}
            >
              {!user ? t('Mijoz') : get(user, 'cardName', '')}
            </Button>
          ) : null}

          <Box
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mb={'20px'}
            gap={'20px'}
          >
            <Box
              width={'100%'}
              display={'flex'}
              // flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'center'}
              flexDirection={'row'}
              gap={'10px'}
            >
              <Box w={'100%'}>
                <Text mb={'5px'}>{t('Kurs')}</Text>
                <Input
                  color={inputText}
                  borderRadius={'15px'}
                  type={'text'}
                  value={course}
                  onChange={(e) => setCourse(e.target.value)}
                />
              </Box>
              {isShowDate ? (
                <Box w={'100%'}>
                  <Text mb={'5px'}>{t('Sana')}</Text>
                  <Input
                    color={inputText}
                    borderRadius={'15px'}
                    type={'date'}
                    value={currentDate}
                    onChange={(e) => setCurrentDate(e.target.value)}
                  />
                </Box>
              ) : (
                ''
              )}
            </Box>
            {isDocTotalaShow ? (
              <Box width={'100%'}>
                <Text
                  color={
                    (Number(summa) > Number(docTotla) && valuta === 'USD') ||
                    (Number(summa) > Number(docTotla * course) &&
                      valuta === 'UZS')
                      ? 'red'
                      : ''
                  }
                  mb={'5px'}
                >
                  {t('Maksimal tolov')}
                  {' : '}
                  {`${docTotla} ${docCurrency} || ${
                    docTotla * course
                  } ${'UZS'}`}
                </Text>
              </Box>
            ) : (
              ''
            )}
            <Box width={'100%'}>
              <Text
                color={
                  (isDocTotalaShow &&
                    Number(summa) > Number(docTotla) &&
                    valuta === 'USD') ||
                  (isDocTotalaShow &&
                    Number(summa) > Number(docTotla * course) &&
                    valuta === 'UZS')
                    ? 'red'
                    : ''
                }
                mb={'5px'}
              >
                {t('sumPaying')}
              </Text>
              <Input
                value={summa}
                onChange={(v) => setSumma(v.target.value)}
                color={inputText}
                borderRadius={'15px'}
                borderColor={
                  (isDocTotalaShow &&
                    Number(summa) > Number(docTotla) &&
                    valuta === 'USD') ||
                  (isDocTotalaShow &&
                    Number(summa) > Number(docTotla * course) &&
                    valuta === 'UZS')
                    ? 'red'
                    : ''
                }
                type={'number'}
              />
            </Box>
          </Box>

          {isTypesShow ? (
            <>
              <Text mb={'5px'}>{t('Tolov usullari')}</Text>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {typeOfPaymentData.map((item, index) => {
                  console.log(item);
                  return (
                    <Button
                      key={index}
                      backgroundColor={
                        item.val === currency ? 'brand.300' : btnDefault
                      }
                      onClick={() => {
                        setCurrency(item.val);
                        if (
                          item.val === get(getMe, 'uBankAccount', '') ||
                          item.val === get(getMe, 'uCartAccount', '')
                        ) {
                          setValuta('UZS');
                          setDisabledCourse(true);
                        } else {
                          setDisabledCourse(false);
                        }
                      }}
                      width={'30%'}
                      color={item.val === currency ? 'white' : textDefault}
                      mb={'20px'}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </Box>
            </>
          ) : null}

          <Text mb={'5px'}>{t('Valyuta')}</Text>
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            mb={'20px'}
          >
            {typeOfValuta.map((item, index) => {
              return (
                <Button
                  key={index}
                  backgroundColor={
                    item.val === valuta ? 'brand.300' : btnDefault
                  }
                  onClick={() => setValuta(item.val)}
                  width={'30%'}
                  color={item.val === valuta ? 'white' : textDefault}
                  mr={'20px'}
                  isDisable={disabledCourse}
                >
                  {item.title}
                </Button>
              );
            })}
          </Box>
        </ModalBody>
        <ModalFooter display={'flex'} justifyContent={'space-between'}>
          <Button backgroundColor="red.600" onClick={onClose}>
            {t('Bekor qilish')}
          </Button>
          <Button
            onClick={accept}
            backgroundColor="green.500"
            isDisable={!valuta || !currency || !summa}
          >
            {t('Tolov qilish')}
          </Button>
        </ModalFooter>
      </ModalContent>
      <ClientsModal
        getRef={(r) => (clientsRef.current = r)}
        onConfirm={(v) => {
          setUser(v);
        }}
      />
    </Modal>
  );
};

export default AddIncomingPayment;
