import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import api from 'api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Input from '../../Input';
import ProductStyle from './ProductStyle';
import { get } from 'lodash';

const ProductsInWarehouseModal = ({ getRef, onConfirm = () => {} }) => {
  const { t } = useTranslation();
  const { token, getMe } = useSelector((state) => state.main);

  const [isOpen, setIsOpen] = useState(false);
  const [titleS, setTitleS] = useState('');
  const [page, setPage] = useState(0);
  const [itemCode, setItemCode] = useState('');

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');

  useEffect(() => {
    const ref = {
      open: (itemCode) => {
        setIsOpen(true);
        setItemCode(itemCode);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const fetchOrders = async ({ queryKey }) => {
    const [_, itemCode, page] = queryKey;
    if (itemCode !== '') {
      let url = `items/by-itemCode/${itemCode}/pagination/${page}`;
      const response = await api.get(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    }
  };

  const { data, isFetching, isError } = useQuery(
    ['ProductsInWarehouseModal', itemCode, page],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent mx="auto" my="auto" maxW={900}>
          <ModalHeader>{t('Xatolik')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProductStyle>
              <Box overflow={'auto'} mb={'15px'}>
                <Box mb={'20px'} className="flex">
                  <Box width={'100'} display={'flex'} alignItems={'center'}>
                    <IconButton
                      isLoading={isFetching}
                      me={'10px'}
                      _hover={false}
                      backgroundColor={'brand.200'}
                      icon={<ArrowLeftIcon color={'white'} w="10px" h="10px" />}
                      onClick={() => setPage((p) => p - 20)}
                      isDisabled={page < 1}
                    />
                    <Text>{page / 20 + 1}</Text>
                    <IconButton
                      onClick={() => setPage((p) => p + 20)}
                      isLoading={isFetching}
                      ms={'10px'}
                      _hover={false}
                      backgroundColor={'brand.200'}
                      icon={
                        <ArrowRightIcon color={'white'} w="10px" h="10px" />
                      }
                    />
                  </Box>
                </Box>
                <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
                  {t('Malumotlarni yuklab olishni iloji bolmadi')}
                </Text>
              </Box>
            </ProductStyle>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto" width="90%" maxW={900}>
        <ModalHeader>{t('Mahsulot Omborda')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProductStyle>
            <Box overflow={'auto'} mb={'15px'}>
              <Box
                mb={'20px'}
                width={'100'}
                className="flex"
                justifyContent={'end'}
              >
                <Box width={'100'} display={'flex'} alignItems={'center'}>
                  <IconButton
                    isLoading={isFetching}
                    me={'10px'}
                    _hover={false}
                    backgroundColor={'brand.200'}
                    icon={<ArrowLeftIcon color={'white'} w="10px" h="10px" />}
                    onClick={() => setPage((p) => p - 20)}
                    isDisabled={page < 1}
                  />
                  <Text>{page / 20 + 1}</Text>
                  <IconButton
                    onClick={() => setPage((p) => p + 20)}
                    isLoading={isFetching}
                    ms={'10px'}
                    _hover={false}
                    backgroundColor={'brand.200'}
                    icon={<ArrowRightIcon color={'white'} w="10px" h="10px" />}
                  />
                </Box>
              </Box>
              <Table
                variant={'unstyled'}
                color="gray.500"
                position={'relative'}
              >
                <Thead>
                  <Tr>
                    <Th
                      px={'10px'}
                      border="1px"
                      borderColor="gray.200"
                      color={tableHead}
                    >
                      {t('Nomi')}
                    </Th>
                    <Th
                      px={'10px'}
                      border="1px"
                      borderColor="gray.200"
                      color={tableHead}
                    >
                      {t('Ombor qoldigi')}
                    </Th>
                    <Th
                      px={'10px'}
                      border="1px"
                      borderColor="gray.200"
                      color={tableHead}
                    >
                      {t('Mavjud miqdor')}
                    </Th>
                    <Th
                      px={'10px'}
                      border="1px"
                      borderColor="gray.200"
                      color={tableHead}
                    >
                      {t('Zakazdagi soni')}
                    </Th>
                    <Th
                      px={'10px'}
                      border="1px"
                      borderColor="gray.200"
                      color={tableHead}
                    >
                      {t('Ombor')}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {mainData.map((value, index) => {
                    return (
                      <Tr
                        key={index}
                        className="tr"
                        _hover={{
                          bg: bgTr,
                        }}
                      >
                        <Td
                          width={'100px'}
                          cursor="pointer"
                          border="1px"
                          borderColor="gray.200"
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          px={'10px'}
                          py={'5px'}
                          color={tablBody}
                        >
                          {get(value, 'itemName', '')}
                        </Td>
                        <Td
                          width={'100px'}
                          cursor="pointer"
                          border="1px"
                          borderColor="gray.200"
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          px={'10px'}
                          py={'5px'}
                          color={tablBody}
                        >
                          {get(value, 'onHand', '')}
                        </Td>
                        <Td
                          width={'100px'}
                          cursor="pointer"
                          border="1px"
                          borderColor="gray.200"
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          px={'10px'}
                          py={'5px'}
                          color={tablBody}
                        >
                          {get(value, 'available', '')}
                        </Td>
                        <Td
                          width={'100px'}
                          cursor="pointer"
                          border="1px"
                          borderColor="gray.200"
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          px={'10px'}
                          py={'5px'}
                          color={tablBody}
                        >
                          {get(value, 'isCommited', '')}
                        </Td>
                        <Td
                          width={'100px'}
                          cursor="pointer"
                          border="1px"
                          borderColor="gray.200"
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          px={'10px'}
                          py={'5px'}
                          color={tablBody}
                        >
                          {get(value, 'whsName', '')}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </ProductStyle>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ProductsInWarehouseModal;
