import styled from 'styled-components';

const AuthStyle = styled.div`
  .text {
    margin-left: 20px;
  }
  @media screen and (max-width: 980px) {
    .logoName {
      display: none;
    }
  }
  .card {
    width: 50% !important;
    height: 100vh !important;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export default AuthStyle;
