import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
  Flex,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddIcon, ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import WarehouseModal from 'components/Modals/WarehouseModal';
import SuccessModal from 'components/Modals/SuccessModal';
import ProductModal from 'components/Modals/ProductModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Style from './Style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import api from 'api';
import ClientsModal from 'components/Modals/ClientsModal';

export default function ViewSalesOrderSHOP() {
  const { t } = useTranslation();

  const { token, getMe } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();
  const productRef = useRef();
  const warehouseRef = useRef();

  const navigation = useNavigate();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnTextColor = useColorModeValue('black', 'white');
  const btnDefault = useColorModeValue('gray.200', 'gray.500');
  const textDefault = useColorModeValue('black', 'White');
  const pdfBtn = useColorModeValue('yellow.600', 'yellow.800');
  const cancelBtn = useColorModeValue('red.600', 'red.800');

  const location = useLocation();
  const data = get(location, 'state', {});
  const documentLines = get(data, 'documentLines', []);
  const docEntry = get(data, 'docEntry', '');

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(documentLines);
  const docTotal = mainData?.reduce(
    (acc, line) => acc + line.unitPrice * line.quantity,
    0,
  );
  const [wData, setwData] = useState({
    warehouseCode: get(mainData, '[0].warehouseCode', ''),
    warehouseName: t('Ombor tanlang'),
  });

  const [user, setUser] = useState({
    cardCode: get(data, 'cardCode', ''),
    cardName: get(data, 'cardName', ''),
    priceListNum: get(data, 'priceListNum', '1'),
  });

  const typeOfValuta = [
    {
      title: t('Dollar'),
      val: 'USD',
    },
    {
      title: t('Som'),
      val: 'UZS',
    },
  ];

  const createNewRequest = () => {
    setIsLoading(true);

    const filteredData = mainData.filter(
      (item) => get(item, 'lineStatus') === 'bost_Open',
    );

    const documentLines = filteredData.map((v, i) => {
      return {
        isChecked: 'yes', // Doim shunday beriladi. Agar no turgan bolsa buni invoicega qoshmaslik kerek boladi.
        baseType: 17,
        baseEntry: docEntry,
        baseLine: get(v, 'lineNum', 0), // Hujjat menyusidagi documentni LineNum. Hujjatni Ichida LineStatus = bost_Open bolsa keyin shu qatorni invoiceni qatoriga qoshish kerek aks xolda yoq.
      };
    });

    let allData = {
      cardCode: get(data, 'cardCode', ''),
      documentLines,
    };

    console.log(allData);

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`invoices/order-to-invoice`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const editItem = () => {
    setIsLoading(true);
    const documentLines = mainData.map((v, i) => {
      return {
        lineNum: get(v, 'lineNum', 0),
        itemCode: get(v, 'itemCode', ''),
        itemDescription: get(v, 'itemDescription', ''),
        quantity: Number(get(v, 'quantity', '')),
        unitPrice: Number(get(v, 'unitPrice', '')),
        warehouseCode: get(wData, 'warehouseCode', ''),
        isUpdated: get(v, 'isEdited', false) ? 'yes' : 'no', // Agarda shu raw ozgarsa isUpdated = yes bolishi kerek aks xolda no bolaveradi. Yangi raw yaratilganda ham isUpdated = yes bolishi kerek
      };
    });

    let allData = {
      cardCode: get(data, 'cardCode', ''),
      documentLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .patch(`orders/shopkeeper/${docEntry}`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };
  const cancelInvoice = () => {
    setIsLoading(true);

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .post(`orders/cancel/${docEntry}`, { docEntry }, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };
  const downloadPDF = () => {
    setIsLoading(true);
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    };
    api
      .get(`pdf-generators/order/${docEntry}`, header)
      .then((response) => {
        setIsLoading(false);
        const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = `order_Pro_Tools_${docEntry}.pdf`; // Specify the file name
        document.body.appendChild(link);
        link.click(); // Trigger the download
        document.body.removeChild(link);
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const addItem = (item) => {
    setMainData((pr) => [
      ...pr,
      {
        lineNum: mainData.length,
        itemCode: get(item, 'itemCode', ''),
        itemDescription: get(item, 'itemName', ''),
        quantity: '',
        unitPrice: get(item, 'price', ''),
        isEdited: true,
      },
    ]);
  };

  const changeQuant = (i, quant) => {
    let changedData = [...mainData];
    changedData[i].quantity = quant;
    changedData[i].isEdited = true;
    setMainData(changedData);
  };

  const changePrice = (i, price_item) => {
    let changedData = [...mainData];
    changedData[i].unitPrice = price_item;
    changedData[i].isEdited = true;
    setMainData(changedData);
  };

  const remove = (i) => {
    let changedData = [...mainData];
    changedData.splice(i, 1);
    changedData[i].isEdited = true;
    setMainData(changedData);
  };

  console.log(data);

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Button minW={'200px'}>{get(user, 'cardName', '')}</Button>
            <Button onClick={() => warehouseRef.current?.open()} minW={'200px'}>
              {get(wData, 'warehouseName', '')}
            </Button>
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={get(data, 'client', '')}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={get(data, 'number', '')}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />

            <Box
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              {typeOfValuta.map((item, index) => {
                return (
                  <Button
                    key={index}
                    backgroundColor={
                      item.val === get(data, 'docCurrency', '')
                        ? 'brand.300'
                        : btnDefault
                    }
                    width={'47%'}
                    color={
                      item.val === get(data, 'docCurrency', '')
                        ? 'white'
                        : textDefault
                    }
                  >
                    {item.title}
                  </Button>
                );
              })}
            </Box>

            <Input
              placeholder={`${t('docTotal')}`}
              value={
                docTotal
                  ? `${t('docTotal')} ${new Intl.NumberFormat().format(
                      docTotal,
                    )}  ${get(data, 'docCurrency', '')}`
                  : ''
              }
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    width={'50px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    {t('Del')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Narxi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Ombor')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        width={'50px'}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        textAlign={'center'}
                      >
                        <IconButton
                          onClick={() => remove(index)}
                          _hover={false}
                          backgroundColor={'red'}
                          icon={
                            <DeleteIcon color={'white'} w="13px" h="13px" />
                          }
                        />
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemDescription', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'quantity', '')}
                          onChange={(title) =>
                            changeQuant(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'unitPrice', '')}
                          onChange={(title) =>
                            changePrice(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'warehouseCode', '')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          <Box justifyContent={'space-between'} display={'flex'} mt={'20px'}>
            <IconButton
              onClick={() =>
                productRef.current?.open(
                  get(user, 'priceListNum', ''),
                  get(wData, 'warehouseCode', ''),
                )
              }
              _hover={false}
              backgroundColor={'brand.200'}
              icon={<AddIcon color={'white'} w="10px" h="10px" />}
            />

            <Box display={'flex'} alignItems={'center'}>
              <Button
                me={'10px'}
                isLoading={isLoading}
                onClick={downloadPDF}
                width={200}
                backgroundColor={pdfBtn}
              >
                {'PDF'}
              </Button>
              <Button
                me={'10px'}
                isLoading={isLoading}
                onClick={cancelInvoice}
                width={200}
                backgroundColor={cancelBtn}
              >
                {'Cancel'}
              </Button>
              <Button
                me={'10px'}
                isLoading={isLoading}
                onClick={editItem}
                width={200}
              >
                {t('Ozgartirish')}
              </Button>
              <Button
                isLoading={isLoading}
                onClick={createNewRequest}
                width={200}
                backgroundColor="green.500"
              >
                {t('Sotuv yaratish')}
              </Button>
            </Box>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      <ProductModal
        getRef={(r) => (productRef.current = r)}
        onConfirm={(item) => addItem(item)}
      />
      <WarehouseModal
        getRef={(r) => (warehouseRef.current = r)}
        onConfirm={(item) => setwData(item)}
      />
    </Style>
  );
}
