import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Input,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import time from 'helpers/time';
import { useQuery } from 'react-query';
import api from 'api';
import { useSelector } from 'react-redux';
const PaymentModal = ({
  getRef,
  isClientShow = false,
  isTypesShow = false,
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);

  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState('');
  const [docTotla, setDocTotla] = useState('');
  const [currency, setCurrency] = useState(1);
  const [summa, setSumma] = useState('');
  const [valuta, setValuta] = useState('USD');
  const [course, setCourse] = useState('');

  const navigate = useNavigate();

  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnDefault = useColorModeValue('gray.200', 'gray.500');
  const textDefault = useColorModeValue('black', 'White');

  useEffect(() => {
    const ref = {
      open: (dTotal) => {
        setIsOpen(true);
        setDocTotla(dTotal);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const fetchOrders = async ({ queryKey }) => {
    const [_, date] = queryKey;

    let url = `currency-rates/by-date`;

    const response = await api.post(
      url,
      {
        currency: 'UZS',
        date: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['getCurrentRate', time.current('YYYY-MM-DD')],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setCourse(resData);
    }
  }, [data]);

  const onClose = () => {
    setIsOpen(false);
  };

  const typeOfPaymentData = [
    {
      title: t('Naqd'),
      val: 1,
    },
    {
      title: t('Karta'),
      val: 2,
    },
    {
      title: t('Terminal'),
      val: 3,
    },
  ];

  const typeOfValuta = [
    {
      title: t('Dollar'),
      val: 'USD',
    },
    {
      title: t('Som'),
      val: 'UZS',
    },
  ];

  const accept = () => {
    setIsOpen(false);
    onConfirm(valuta, summa);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto">
        <ModalHeader>{t('Tolov amalga oshiring')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isClientShow ? (
            <Button width="100%" mb={'20px'}>
              {t('Mijoz')}
            </Button>
          ) : null}

          <Box
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mb={'20px'}
          >
            <Box width={'100%'}>
              <Text
                color={Number(summa) > Number(docTotla) ? 'red' : ''}
                mb={'5px'}
              >
                {t('Maksimal tolov')}
                {' : '}
                {docTotla}
              </Text>
              <Input
                value={summa}
                onChange={(v) => setSumma(v.target.value)}
                color={inputText}
                borderRadius={'15px'}
                type={'number'}
              />
            </Box>
            <Box width={'45%'}>
              <Text mb={'5px'}>
                {t('Kurs')}
                {': '}
              </Text>
              <Input
                color={inputText}
                borderRadius={'15px'}
                type={'text'}
                value={course}
                onChange={(e) => setCourse(e.target.value)}
              />
            </Box>
          </Box>

          {isTypesShow ? (
            <>
              <Text mb={'5px'}>{t('Tolov usullari')}</Text>
              <Box
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                {typeOfPaymentData.map((item, index) => {
                  return (
                    <Button
                      key={index}
                      backgroundColor={
                        item.val === currency ? 'brand.300' : btnDefault
                      }
                      onClick={() => setCurrency(item.val)}
                      width={'30%'}
                      color={item.val === currency ? 'white' : textDefault}
                      mb={'20px'}
                    >
                      {item.title}
                    </Button>
                  );
                })}
              </Box>
            </>
          ) : null}

          <Text mb={'5px'}>{t('Valyuta')}</Text>
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            mb={'20px'}
          >
            {typeOfValuta.map((item, index) => {
              return (
                <Button
                  key={index}
                  backgroundColor={
                    item.val === valuta ? 'brand.300' : btnDefault
                  }
                  onClick={() => setValuta(item.val)}
                  width={'30%'}
                  color={item.val === valuta ? 'white' : textDefault}
                  mr={'20px'}
                >
                  {item.title}
                </Button>
              );
            })}
          </Box>
        </ModalBody>
        <ModalFooter display={'flex'} justifyContent={'space-between'}>
          <Button backgroundColor="red.600" onClick={onClose}>
            {t('Bekor qilish')}
          </Button>
          <Button
            onClick={accept}
            backgroundColor="green.500"
            isDisable={Number(summa) > Number(docTotla)}
          >
            {t('Tolov qilish')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default PaymentModal;
