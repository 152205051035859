import React, { useRef, useState, useEffect } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Box,
  Switch,
  Text,
  Input,
} from '@chakra-ui/react';
import BussinesPartnerModal from 'components/Modals/BussinesPartnerModal';
import { useLocation, useNavigate } from 'react-router-dom';
import SuccessModal from 'components/Modals/SuccessModal';
import RegionsModal from 'components/Modals/RegionsModal';
import ErrorModal from 'components/Modals/ErrorModal';
import { ArrowBackIcon } from '@chakra-ui/icons';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';
import PriceListModal from '../../../components/Modals/PriceListModal/index';
import { useQuery } from 'react-query';

export default function ViewListClientsSHOP() {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();
  const regionsModal = useRef();
  const bussinessPartnerRef = useRef();
  const priceListRef = useRef();

  const navigation = useNavigate();
  const btnColor = useColorModeValue('gray.300', 'gray.500');
  const btnTextColor = useColorModeValue('black', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const cardCode = get(data, 'cardCode', '');
  const [priceData, setPriceData] = useState({
    priceListNumber: get(data, 'priceListNum', ''),
    priceListName: '',
  });

  const isActiv = get(data, 'valid', false) === 'tYES';

  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(isActiv);
  const [userPhone, setUserPhone] = useState(get(data, 'phone1', ''));
  const [userName, setUserName] = useState(get(data, 'cardName', ''));
  const [wData, setwData] = useState({
    code: get(data, 'groupCode', ''),
    name: '',
  });

  const [region, setRegion] = useState({
    id: '',
    name: get(data, 'region', ''),
  });

  const createNewRequest = () => {
    setIsLoading(true);

    let allData = {
      cardName: userName,
      groupCode: get(wData, 'code', ''),
      phone1: userPhone,
      region: get(region, 'name', ''),
      valid: isActive ? 'tYES' : 'tNO',
      priceListNum: get(priceData, 'priceListNumber', ''),
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .patch(`business-partners/${cardCode}`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const fetchPriceList = async () => {
    const response = await api.get(
      `item-price-lists/${get(data, 'priceListNum', '')}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const resData = JSON.parse(response.data).data;
    console.log('resData = ', resData);

    setPriceData({
      priceListNumber: resData.priceListNumber,
      priceListName: resData.priceListName,
    });
  };

  const fetchBpGroups = async (skip = 0) => {
    const response = await api.get(
      `business-partner-groups/pagination/${skip}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    const resData = JSON.parse(response.data).data;
    const res = resData.find((group) => {
      return group.code === get(data, 'groupCode', '');
    });
    console.log('res = ', res);

    setwData({
      code: get(res, 'code', ''),
      name: get(res, 'name', ''),
    });
  };

  useEffect(() => {
    fetchPriceList();
    fetchBpGroups();
  }, []);

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Button
              onClick={() => bussinessPartnerRef.current?.open()}
              minW={'100%'}
              backgroundColor={btnColor}
              color={btnTextColor}
            >
              {get(wData, 'name', '')}
            </Button>
            <Button
              onClick={() => regionsModal.current?.open()}
              minW={'100%'}
              backgroundColor={btnColor}
              color={btnTextColor}
            >
              {get(region, 'name', '')}
            </Button>
            <Button
              onClick={() => priceListRef.current?.open()}
              minW={'100%'}
              backgroundColor={btnColor}
              color={btnTextColor}
            >
              {get(priceData, 'priceListName', '')}
            </Button>
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={userName}
              onChange={(v) => setUserName(v.target.value)}
              borderRadius={'15px'}
              color={btnTextColor}
            />
            <Input
              value={userPhone}
              onChange={(v) => setUserPhone(v.target.value)}
              borderRadius={'15px'}
              color={btnTextColor}
            />
            <Box display={'flex'} alignItems={'center'}>
              <Text me={'10px'}>{t('Aktiv')}</Text>
              <Switch
                colorScheme={'green'}
                onChange={(v) => {
                  setIsActive(v.target.checked);
                }}
                isChecked={isActive}
              />
            </Box>
          </SimpleGrid>

          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={createNewRequest}
              width={250}
            >
              {t('Yangilash')}
            </Button>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      <BussinesPartnerModal
        getRef={(r) => (bussinessPartnerRef.current = r)}
        onConfirm={(item) => setwData(item)}
      />
      <RegionsModal
        getRef={(r) => (regionsModal.current = r)}
        onConfirm={(item) => setRegion(item)}
      />
      <PriceListModal
        getRef={(r) => (priceListRef.current = r)}
        onConfirm={(item) => setPriceData(item)}
      />
    </Style>
  );
}
