import React, { useRef, useState } from 'react';
import { useColorModeValue, IconButton, Box, Text } from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import SuccessModal from 'components/Modals/SuccessModal';
import ProductModal from 'components/Modals/ProductModal';
import PaymentModal from 'components/Modals/PaymentModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';

export default function CreateOutgoingPaymentSHOP() {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();
  const paymentRef = useRef();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const redText = useColorModeValue('red', 'white');
  const greenText = useColorModeValue('green', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const stockTransferLines = get(data, 'stockTransferLines', []);

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(stockTransferLines);

  const createNewRequest = () => {
    // setIsLoading(true);
    // const stockTransferLines = mainData.map((v, i) => {
    //   return {
    //     lineNum: get(v, 'lineNum', 0),
    //     itemCode: get(v, 'itemCode', ''),
    //     quantity: Number(get(v, 'quantity', '')),
    //     itemDescription: get(v, 'itemDescription', ''),
    //   };
    // });
    // let allData = {
    //   isMoved: 'N',
    //   stockTransferLines,
    // };
    // let header = {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // };
    // api
    //   .patch(`inventory-transfer-requests/${docEntry}`, allData, header)
    //   .then(() => {
    //     setIsLoading(false);
    //     successRef.current?.open();
    //   })
    //   .catch((err) => {
    //     setIsLoading(false);
    //     const errData = get(err.response, 'data', '{}');
    //     errorRef.current?.open(errData);
    //   });
  };

  const addItem = (item) => {
    setMainData((pr) => [
      ...pr,
      {
        lineNum: mainData.length,
        itemCode: get(item, 'itemCode', ''),
        itemDescription: get(item, 'itemName', ''),
        quantity: '',
      },
    ]);
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <Box className="topCard">
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {'№:'}
                </Text>
                <Text>{get(data, 'docNum', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {t('Статус')}
                  {':'}
                </Text>
                {get(data, 'isMoved', '') === 'N' ? (
                  <Text color={redText}>{t('Qabul qilinmadi')}</Text>
                ) : (
                  <Text color={greenText}>{t('Qabul qilindi')}</Text>
                )}
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {t('Дата создания')}
                  {':'}
                </Text>
                <Text>{time.to(get(data, 'docDate', ''), 'DD-MM-YYYY')}</Text>
              </Box>
            </Box>
            <Button onClick={() => paymentRef.current?.open()}>
              {t('Pul kirimi')}
            </Button>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />

      <PaymentModal
        getRef={(r) => (paymentRef.current = r)}
        onConfirm={(item) => addItem(item)}
      />
    </Style>
  );
}
