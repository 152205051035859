import React from 'react';
import {
  Box,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import Style from './Style';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import Card from 'components/card/Card';
import { get } from 'lodash';
import time from 'helpers/time';

export default function ViewCompleted() {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const documentLines = get(data, 'stockTransferLines', []);

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <SimpleGrid
            columns={{ base: 1, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('Дата создания')}
                {': '}
              </Text>
              <Text>{time.to(get(data, 'docDate', ''), 'DD-MM-YYYY')}</Text>
            </Box>
          </SimpleGrid>
          <Table variant={'unstyled'} color="gray.500">
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  width={'50px'}
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {'№'}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('Наименование товара')}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('Кол-во')}
                </Th>

                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Qaysi ombordan')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Qaysi omborga')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {documentLines.map((v, i) => {
                return (
                  <Tr key={i}>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'lineNum', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'itemDescription', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'quantity', '')}
                    </Td>

                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'fromWarehouseCode', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'warehouseCode', '')}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Card>
      </Box>
    </Style>
  );
}
