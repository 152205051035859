import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Box,
  Td,
  Th,
  Tr,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Style from './Style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';

export default function ViewIncomingPending() {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const redText = useColorModeValue('red', 'white');
  const greenText = useColorModeValue('green', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const docEntry = get(data, 'docEntry', '');
  const mainData = get(data, 'stockTransferLines', []);

  const [isLoading, setIsLoading] = useState(false);

  const createNewRequest = () => {
    setIsLoading(true);
    const stockTransferLines = mainData.map((v, i) => {
      return {
        baseType: 'InventoryTransferRequest',
        baseLine: get(v, 'lineNum', 0),
        baseEntry: docEntry,
      };
    });

    let allData = {
      stockTransferLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .post(`stock-transfers`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {'№:'}
                </Text>
                <Text>{get(data, 'docNum', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {t('Статус')}
                  {':'}
                </Text>
                {get(data, 'isMoved', '') === 'N' ? (
                  <Text color={redText}>{t('Qabul qilinmadi')}</Text>
                ) : (
                  <Text color={greenText}>{t('Qabul qilindi')}</Text>
                )}
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead} me={'5px'}>
                  {t('Дата создания')}
                  {':'}
                </Text>
                <Text>{time.to(get(data, 'docDate', ''), 'DD-MM-YYYY')}</Text>
              </Box>
            </Box>
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>
                      
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemDescription', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'quantity', '')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={createNewRequest}
              width={200}
              backgroundColor="green.500"
            >
              {t('Qabul qilish')}
            </Button>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
    </Style>
  );
}
