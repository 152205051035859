import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Box,
  Switch,
  Text,
  Input,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Style from './Style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import api from 'api';
import BussinesPartnerModal from 'components/Modals/BussinesPartnerModal';
import RegionsModal from 'components/Modals/RegionsModal';
import PriceListModal from '../../../components/Modals/PriceListModal/index';

export default function CreateListClientsSHOP() {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();
  const regionsModal = useRef();
  const bussinessPartnerRef = useRef();
  const priceListRef = useRef();

  const navigation = useNavigate();
  const btnColor = useColorModeValue('gray.300', 'gray.500');
  const btnTextColor = useColorModeValue('black', 'white');

  const [isLoading, setIsLoading] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [userPhone, setUserPhone] = useState('+998');
  const [userName, setUserName] = useState('');
  const [wData, setwData] = useState({
    code: '',
    name: t('Biznes hamkor tanlang'),
  });
  const [region, setRegion] = useState({
    id: '',
    name: t('Manzilni tanlang'),
  });
  const [priceData, setPriceData] = useState({
    priceListNumber: '',
    priceListName: t('Narx royxati'),
  });

  const createNewRequest = () => {
    setIsLoading(true);

    let allData = {
      cardName: userName,
      groupCode: get(wData, 'code', ''),
      phone1: userPhone,
      region: get(region, 'name', ''),
      valid: isActive ? 'tYES' : 'tNO',
      priceListNum: 2,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .post(`business-partners`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Button
              onClick={() => bussinessPartnerRef.current?.open()}
              minW={'100%'}
              backgroundColor={btnColor}
              color={btnTextColor}
            >
              {get(wData, 'name', '')}
            </Button>
            <Button
              onClick={() => regionsModal.current?.open()}
              minW={'100%'}
              backgroundColor={btnColor}
              color={btnTextColor}
            >
              {get(region, 'name', '')}
            </Button>
            <Button
              onClick={() => priceListRef.current?.open()}
              minW={'100%'}
              backgroundColor={btnColor}
              color={btnTextColor}
            >
              {get(priceData, 'priceListName', '')}
            </Button>
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={userName}
              onChange={(v) => setUserName(v.target.value)}
              borderRadius={'15px'}
              color={btnTextColor}
            />
            <Input
              value={userPhone}
              onChange={(v) => setUserPhone(v.target.value)}
              borderRadius={'15px'}
              color={btnTextColor}
            />
            <Box display={'flex'} alignItems={'center'}>
              <Text me={'10px'}>{t('Aktiv')}</Text>
              <Switch
                colorScheme={'green'}
                onChange={(v) => {
                  setIsActive(v.target.checked);
                }}
                isChecked={isActive}
              />
            </Box>
          </SimpleGrid>

          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={createNewRequest}
              width={250}
            >
              {t('Yaratish')}
            </Button>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      <BussinesPartnerModal
        getRef={(r) => (bussinessPartnerRef.current = r)}
        onConfirm={(item) => setwData(item)}
      />
      <RegionsModal
        getRef={(r) => (regionsModal.current = r)}
        onConfirm={(item) => setRegion(item)}
      />
      <PriceListModal
        getRef={(r) => (priceListRef.current = r)}
        onConfirm={(item) => setPriceData(item)}
      />
    </Style>
  );
}
