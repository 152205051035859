import React, { useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon, CheckIcon } from '@chakra-ui/icons';
import Style from './Style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import api from 'api';
import ErrorModal from 'components/Modals/ErrorModal';
import SuccessModal from 'components/Modals/SuccessModal';

export default function ViewSalesDepartment() {
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const blue = useColorModeValue('lightBlue.50', 'lightBlue.700');
  const gray = useColorModeValue('gray.200', 'gray.700');

  const location = useLocation();
  const data = get(location, 'state', {});
  const docEntry = get(data, 'docEntry', '');
  const documentLines = get(data, 'documentLines', []);
  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(documentLines);

  const getChecked = (i, v) => {
    let changedValue = v ? 'yes' : 'no';
    let changedData = [...mainData];
    changedData[i].isChecked = changedValue;
    setMainData(changedData);
  };

  const acceptCheckbox = () => {
    setIsLoading(true);
    const documentLines = mainData.map((v, i) => {
      return {
        lineNum: get(v, 'lineNum', ''),
        isChecked: get(v, 'isChecked', ''),
      };
    });

    let allData = { documentLines };
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .patch(`orders/check-box-status/${docEntry}`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const accept = () => {
    setIsLoading(true);
    const documentLines = mainData.map((v, i) => {
      return {
        lineNum: get(v, 'lineNum', ''),
        itemCode: get(v, 'itemCode', ''),
        itemDescription: get(v, 'itemDescription', ''),
        quantity: get(v, 'quantity', ''),
        currency: get(v, 'currency', ''),
        unitPrice: get(v, 'unitPrice', ''),
      };
    });

    let allData = {
      uDocumentStatus: 'shipped', // doim shipped berib yuborasiz
      cardCode: get(data, 'cardCode', ''),
      documentLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(allData);

    api
      .patch(`orders/${docEntry}`, allData, header)
      .then((res) => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <SimpleGrid
            columns={{ base: 1, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Поставщик')}
                  {' : '}
                </Text>
                <Text>{get(data, 'cardName', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Статус')}
                  {' : '}
                </Text>
                <Text>{get(data, 'uDocumentStatus', '')}</Text>
              </Box>
            </Box>
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Дата создания')}
                  {' : '}
                </Text>
                <Text>{get(data, 'docDate', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Всего')}
                  {' : '}
                </Text>
                <Text>
                  {get(data, 'docTotal', '')} {get(data, 'docCurrency', '')}
                </Text>
              </Box>
            </Box>
          </SimpleGrid>

          <Table variant={'unstyled'} color="gray.500">
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  width={'50px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {'№'}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Наименование товара')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Кол-во')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Цена')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('№ Склада')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Общая цена')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Belgilash')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mainData.map((v, i) => {
                return get(getMe, 'uWarehouse', '') ===
                  get(v, 'warehouseCode', '') ? (
                  <Tr
                    key={i}
                    backgroundColor={
                      get(getMe, 'uWarehouse', '') ===
                      get(v, 'warehouseCode', '')
                        ? blue
                        : gray
                    }
                  >
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'lineNum', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'itemDescription', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'quantity', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'price', '')} {get(v, 'currency', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'warehouseCode', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'lineTotal', '')} {get(v, 'currency', '')}
                    </Td>

                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Checkbox
                          borderColor={'brand.200'}
                          disabled={
                            get(getMe, 'uWarehouse', '') !==
                            get(v, 'warehouseCode', '')
                          }
                          defaultChecked={get(v, 'isChecked', '') !== 'no'}
                          onChange={(v) => getChecked(i, v.target.checked)}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ) : null;
              })}
            </Tbody>
          </Table>

          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={acceptCheckbox}
              width={200}
              backgroundColor="green.500"
              me={'10px'}
            >
              <CheckIcon /> {t('Saqlash')}
            </Button>

            <Button isLoading={isLoading} width={200} onClick={accept}>
              {t('Отгузить')}
            </Button>
          </Box>
        </Card>
      </Box>
      <ErrorModal
        getRef={(r) => {
          errorRef.current = r;
        }}
      />
      <SuccessModal
        getRef={(r) => {
          successRef.current = r;
        }}
      />
    </Style>
  );
}
