import React from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import Style from './Style';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';

export default function Viewshipments() {
  const { t } = useTranslation();
  const { getMe } = useSelector((state) => state.main);

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const blue = useColorModeValue('lightBlue.50', 'lightBlue.700');
  const gray = useColorModeValue('gray.200', 'gray.700');

  const location = useLocation();
  const data = get(location, 'state', {});
  const documentLines = get(data, 'documentLines', []);

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <SimpleGrid
            columns={{ base: 1, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Поставщик')}
                  {' : '}
                </Text>
                <Text>{get(data, 'cardName', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Статус')}
                  {' : '}
                </Text>
                <Text>{get(data, 'uDocumentStatus', '')}</Text>
              </Box>
            </Box>
            <Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Дата создания')}
                  {' : '}
                </Text>
                <Text>{get(data, 'docDate', '')}</Text>
              </Box>
              <Box display={'flex'}>
                <Text color={tableHead}>
                  {t('Всего')}
                  {' : '}
                </Text>
                <Text>
                  {get(data, 'docTotal', '')} {get(data, 'docCurrency', '')}
                </Text>
              </Box>
            </Box>
          </SimpleGrid>
          <Table variant={'unstyled'} color="gray.500">
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  width={'50px'}
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {'№'}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('Наименование товара')}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('Кол-во')}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('Цена')}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('№ Склада')}
                </Th>
                <Th
                  width={'100px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('Belgilash')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {documentLines.map((v, i) => {
                return get(getMe, 'uWarehouse', '') ===
                  get(v, 'warehouseCode', '') ? (
                  <Tr
                    key={i}
                    backgroundColor={
                      get(getMe, 'uWarehouse', '') ===
                      get(v, 'warehouseCode', '')
                        ? blue
                        : gray
                    }
                  >
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'lineNum', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'itemDescription', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'quantity', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'price', '')} {get(v, 'currency', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(v, 'warehouseCode', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                      >
                        <Checkbox
                          borderColor={'brand.200'}
                          disabled={true}
                          defaultChecked={get(v, 'isChecked', '') !== 'no'}
                        />
                      </Box>
                    </Td>
                  </Tr>
                ) : null;
              })}
            </Tbody>
          </Table>
        </Card>
      </Box>
    </Style>
  );
}
