import { createSlice } from '@reduxjs/toolkit';

export const main = createSlice({
  name: 'main',
  initialState: {
    getMe: {},
    token: '',
    language: 'uz',
    isOpened: true,
    headerScreenName: 'Dashboard',
    jobTitle: '',
  },
  reducers: {
    setMe: (state, action) => {
      state.getMe = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setIsOpened: (state, action) => {
      state.isOpened = action.payload;
    },
    setHeaderScreenName: (state, action) => {
      state.headerScreenName = action.payload;
    },
    setJobTitle: (state, action) => {
      state.jobTitle = action.payload;
    },
  },
});
