import React, { useRef, useState } from 'react';
import { Box, Text, Input, useColorModeValue } from '@chakra-ui/react';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import Button from 'components/Button';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';

export default function DollarCourse() {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();

  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().split('T')[0],
  );
  const [dValue, setdValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const inputText = useColorModeValue('gray.700', 'gray.100');

  const saveCurrency = () => {
    setIsLoading(true);

    let allData = {
      currency: 'UZS',
      rate: dValue,
      rateDate: new Date(currentDate).toISOString(),
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    
    api
      .post(`currency-rates`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <Box
            display={'flex'}
            flexWrap={'wrap'}
            alignItems={'center'}
            justifyContent={'space-between'}
            mb={'20px'}
          >
            <Box width={'45%'}>
              <Text mb={'5px'}>{t('Dollar kursi')}</Text>

              <Input
                color={inputText}
                borderRadius={'15px'}
                type={'number'}
                value={dValue}
                onChange={(e) => setdValue(e.target.value)}
              />
            </Box>
            <Box width={'45%'}>
              <Text mb={'5px'}>{t('Sana')}</Text>
              <Input
                color={inputText}
                borderRadius={'15px'}
                type={'date'}
                value={currentDate}
                onChange={(e) => setCurrentDate(e.target.value)}
              />
            </Box>
          </Box>
          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={saveCurrency}
              width={200}
              backgroundColor="green.500"
            >
              {t('Saqlash')}
            </Button>
          </Box>
        </Card>
      </Box>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} isBack={false} />
    </Style>
  );
}
