import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import Style from './Style';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import { get } from 'lodash';
import api from 'api';
import { ArrowBackIcon } from '@chakra-ui/icons';
import Button from 'components/Button';

export default function StockMiniGroup() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { token } = useSelector((state) => state.main);

  const location = useLocation();
  const sendDate = get(location, 'state', {});
  const groupNumber = get(sendDate, 'number', '');

  const [page, setPage] = useState(0);
  const [iName, setIName] = useState('');
  const [uType, setUType] = useState('');
  const [uBrandType, setuBrandType] = useState('');

  const searchIconColor = useColorModeValue('white');
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');
  const textErrorColor = useColorModeValue('red.600', 'red.600');

  const fetchOrders = async ({ queryKey }) => {
    const [_, groupNumber, iName, uType, uBrandType, page] = queryKey;

    let header = {
      Authorization: `Bearer ${token}`,
    };

    if (iName.length > 0) {
      header.itemName = iName;
    }
    if (uType.length > 0) {
      header.itemType = uType;
    }
    if (uBrandType.length > 0) {
      header.brandType = uBrandType;
    }

    let url = `items/by-groupCode/${groupNumber}/search-by-name-type-brand/pagination/${page}`;

    const response = await api.get(url, {
      headers: header,
    });

    return response.data;
  };

  const { data, isLoading, isError, isFetching } = useQuery(
    ['stockMiniGroup', groupNumber, iName, uType, uBrandType, page],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <IconButton
            onClick={() => navigation(-1)}
            backgroundColor={'brand.200'}
            icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
            mb={'5px'}
          />
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <Box mb={'20px'} className={'flex'}>
              <Box display={'flex'}>
                <Input
                  isLoading={isLoading}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={iName}
                  onConfirm={() => {
                    setPage(0);
                  }}
                  onChange={(v) => setIName(v.target.value)}
                  placeholder={t('Nomi')}
                />
                <Input
                  isIconHas={false}
                  isLoading={isLoading}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={uType}
                  onChange={(v) => setUType(v.target.value)}
                  placeholder={t('Turi')}
                />
              </Box>
              <Box width={'100'} display={'flex'} alignItems={'center'}>
                <IconButton
                  isLoading={isFetching}
                  me={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                  }
                  onClick={() => setPage((p) => p - 20)}
                  isDisabled={page < 1}
                />
                <Text>{page / 20 + 1}</Text>
                <IconButton
                  onClick={() => setPage((p) => p + 20)}
                  isLoading={isFetching}
                  ms={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                  }
                />
              </Box>
            </Box>
            <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
              {t(
                'Malumotlarni yuklab olishni iloji bolmadi Iltimos qaytadan urunib koring yoki qaytadan akkauntga kirib koring',
              )}
            </Text>
            <Button
              alignSelf={'flex-end'}
              backgroundColor={'red.600'}
              onClick={() => navigation('/')}
            >
              {t('Chiqish')}
            </Button>
          </Card>
        </Box>
      </Style>
    );
  }

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />
        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <Box mb={'20px'} className="flex">
            <Box display={'flex'}>
              <Input
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={iName}
                onChange={(v) => setIName(v.target.value)}
                placeholder={t('Nomi')}
              />
              <Input
                isIconHas={false}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={uType}
                onChange={(v) => setUType(v.target.value)}
                placeholder={t('Maxsulot turi')}
              />
              <Input
                isIconHas={false}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={uBrandType}
                onChange={(v) => setuBrandType(v.target.value)}
                placeholder={t('Brand turi')}
              />
            </Box>
            <Box width={'100'} display={'flex'} alignItems={'center'}>
              <IconButton
                isLoading={isFetching}
                me={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                }
                onClick={() => {
                  // getInfo(page - 10);
                  setPage((p) => p - 20);
                }}
                isDisabled={page < 1}
              />
              <Text>{page / 20 + 1}</Text>
              <IconButton
                onClick={() => {
                  // getInfo(page + 10);
                  setPage((p) => p + 20);
                }}
                isLoading={isFetching}
                ms={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                }
              />
            </Box>
          </Box>
          <Table variant={'unstyled'} color="gray.500" position={'relative'}>
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Guruh nomi')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Mavjud')}
                </Th>

                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Tipi')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Brand tipi')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Ombor')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mainData.map((value, index) => {
                return (
                  <Tr
                    key={index}
                    // className="tr"
                    _hover={{
                      bg: bgTr,
                    }}
                  >
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={
                        get(value, 'minLevel', '') >=
                        get(value, 'totalOnHand', '')
                          ? textErrorColor
                          : tablBody
                      }
                    >
                      {get(value, 'itemName', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={
                        get(value, 'minLevel', '') >=
                        get(value, 'totalOnHand', '')
                          ? textErrorColor
                          : tablBody
                      }
                    >
                      {get(value, 'totalOnHand', '')}
                    </Td>

                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={
                        get(value, 'minLevel', '') >=
                        get(value, 'totalOnHand', '')
                          ? textErrorColor
                          : tablBody
                      }
                    >
                      {get(value, 'uItemType', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={
                        get(value, 'minLevel', '') >
                        get(value, 'totalOnHand', '')
                          ? textErrorColor
                          : tablBody
                      }
                    >
                      {get(value, 'uBrandType', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={
                        get(value, 'minLevel', '') >
                        get(value, 'totalOnHand', '')
                          ? textErrorColor
                          : tablBody
                      }
                    >
                      {get(value, 'whsName', '')}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Card>
      </Box>
    </Style>
  );
}
