import React from 'react';
import { Box, Icon, SimpleGrid, Text, Spinner, Flex } from '@chakra-ui/react';
import { MdAttachMoney, MdBarChart } from 'react-icons/md';
import { PiMoneyLight } from 'react-icons/pi';

import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import api from 'api';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

export default function UserReports() {
  const { t } = useTranslation();

  const { token } = useSelector((state) => state.main);

  const fetchTodaysInvoices = async () => {
    const { data } = await api.get('invoices/today-statistics/pagination/0', {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(JSON.parse(data).data);

    return JSON.parse(data).data;
  };

  const {
    data: todayInvoices,
    isFetching: isFetchingTodayInvoice,
    isError: isErrorTodayInvoice,
  } = useQuery(['todaysInvoices'], fetchTodaysInvoices, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const fetchTodaysIncomings = async () => {
    const { data } = await api.get(
      'incoming-payments/today-statistics/pagination/0',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return JSON.parse(data).data;
  };

  const {
    data: todayIncomings,
    isFetching: isFetchingTodayIncomings,
    isError: isErrorTodayIncomings,
  } = useQuery(['todaysIncomingss'], fetchTodaysIncomings, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const fetchTodaysOutgoings = async () => {
    const { data } = await api.get(
      'outgoing-payments/today-statistics/pagination/0',
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    console.log(JSON.parse(data).data);

    return JSON.parse(data).data;
  };

  const {
    data: todayOutgoings,
    isFetching: isFetchingTodayOutgoings,
    isError: isErrorTodayOutgoings,
  } = useQuery(['todaysOutgoings'], fetchTodaysOutgoings, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  if (isErrorTodayInvoice || isErrorTodayIncomings || isFetchingTodayInvoice) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
          {t('Malumotlarni yuklab olishni iloji bolmadi')}
        </Text>
      </Box>
    );
  }
  if (
    isFetchingTodayOutgoings ||
    isFetchingTodayIncomings ||
    isErrorTodayOutgoings
  ) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Flex alignItems="center" justifyContent="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      </Box>
    );
  }
  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Flex direction="column" gap="20px" mb="20px">
        <Flex direction="column" gap="20px">
          <Text fontSize={'xl'} fontWeight={'bold'} color={'black'}>
            {t('Today invoices')}
          </Text>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                  icon={
                    <Icon w="32px" h="32px" as={MdBarChart} color={'white'} />
                  }
                />
              }
              name={t('Today invoices')}
              value={`$ ${new Intl.NumberFormat('fr-FR').format(
                todayInvoices[0]?.dollarAmount,
              )}`}
            />
          </SimpleGrid>
        </Flex>
        <Flex direction="column" gap="20px">
          <Text fontSize={'xl'} fontWeight={'bold'} color={'black'}>
            {t('Today incoming payments')}
          </Text>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            {todayIncomings.map((item, index) => (
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={item.sumAmount > 0 ? PiMoneyLight : MdAttachMoney}
                        color={'white'}
                      />
                    }
                  />
                }
                name={`${item.acctName}`}
                value={`${
                  item.sumAmount > 0
                    ? `${new Intl.NumberFormat('fr-FR').format(
                        item.sumAmount,
                      )} uzs`
                    : `$ ${new Intl.NumberFormat('fr-FR').format(
                        item.dollarAmount,
                      )}`
                }`}
              />
            ))}
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdAttachMoney}
                      color={'white'}
                    />
                  }
                />
              }
              name={`${t('Total')}`}
              value={`$ ${new Intl.NumberFormat('fr-FR').format(
                todayIncomings.reduce(
                  (a, b) => a + parseFloat(b.dollarAmount),
                  0,
                ),
              )}`}
            />
          </SimpleGrid>
        </Flex>
        <Flex direction="column" gap="20px">
          <Text fontSize={'xl'} fontWeight={'bold'} color={'black'}>
            {t('Today outgoing payments')}
          </Text>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            {todayOutgoings.map((item, index) => (
              <MiniStatistics
                startContent={
                  <IconBox
                    w="56px"
                    h="56px"
                    bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                    icon={
                      <Icon
                        w="32px"
                        h="32px"
                        as={item.sumAmount > 0 ? PiMoneyLight : MdAttachMoney}
                        color={'white'}
                      />
                    }
                  />
                }
                name={`${item.acctName}`}
                value={` ${
                  item.sumAmount > 0
                    ? `${new Intl.NumberFormat('fr-FR').format(
                        item.sumAmount,
                      )} uzs`
                    : `$
                    ${new Intl.NumberFormat('fr-FR').format(item.dollarAmount)}`
                }`}
              />
            ))}
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdAttachMoney}
                      color={'white'}
                    />
                  }
                />
              }
              name={`${t('Total')}`}
              value={`$ ${new Intl.NumberFormat('fr-FR').format(
                todayOutgoings.reduce(
                  (a, b) => a + parseFloat(b.dollarAmount),
                  0,
                ),
              )}`}
            />
          </SimpleGrid>
        </Flex>
      </Flex>
    </Box>
  );
}
