import React from 'react';
import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  const textColor = useColorModeValue('brand.600', 'white');
  const { t } = useTranslation();
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: 'column',
        xl: 'row',
      }}
      alignItems={{
        base: 'center',
        xl: 'start',
      }}
      justifyContent="space-between"
      px={{ base: '30px', md: '50px' }}
      pb="5px"
    >
      <Text
        color={textColor}
        textAlign={{
          base: 'center',
          xl: 'start',
        }}
        mb={{ base: '20px', xl: '0px' }}
      >
        {' '}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          {t('BIS kompaniyasi tomonidan yaratildi')}
        </Text>
      </Text>
      <button
        href="https://bispro.uz"
        onClick={() => window.open('https://bispro.uz')}
      >
        <Text fontWeight="600" color={textColor}>
          {t('Biz haqimizda')}
        </Text>
      </button>
    </Flex>
  );
}
