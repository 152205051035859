import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
  Flex,
  Select,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import WarehouseModal from 'components/Modals/WarehouseModal';
import SuccessModal from 'components/Modals/SuccessModal';
import ProductModal from 'components/Modals/ProductModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Style from './Style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import api from 'api';
import ClientsModal from 'components/Modals/ClientsModal';
import ProductsInWarehouseModal from '../../../components/Modals/ProductsInWarehouseModal/index';
import { AutoComplete, Tag } from 'antd';
import { useQuery } from 'react-query';

export default function CreateSalesSHOP() {
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);
  const uWarehouse = get(getMe, 'uWarehouse', '');

  const errorRef = useRef();
  const successRef = useRef();
  const productRef = useRef();
  const productsInWhsRef = useRef();
  const warehouseRef = useRef();
  const clientsRef = useRef();

  const navigation = useNavigate();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnTextColor = useColorModeValue('black', 'white');
  const btnDefault = useColorModeValue('gray.200', 'gray.500');
  const textDefault = useColorModeValue('black', 'White');

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(
    Array(10)
      .fill()
      .map(() => ({
        itemCode: '',
        itemName: '',
        quantity: '',
        unitPrice: '',
        warehouseCode: '',
        warehouseName: '',
      })),
  );
  const [userPhone, setUserPhone] = useState('+998');
  const [userName, setUserName] = useState('');
  const [valuta, setValuta] = useState('USD');
  const [searchItemName, setSearchItemName] = useState('');
  const docTotal = mainData?.reduce(
    (acc, line) => acc + line.unitPrice * line.quantity,
    0,
  );
  const [wData, setwData] = useState({
    warehouseCode: uWarehouse,
    warehouseName: `${t('Ombor tanlang')} : ${uWarehouse}`,
  });

  const [user, setUser] = useState({
    cardCode: '',
    cardName: t('Foydalanuvchini tanlang'),
    priceListNum: '1',
  });

  const typeOfValuta = [
    {
      title: t('Dollar'),
      val: 'USD',
    },
    {
      title: t('Som'),
      val: 'UZS',
    },
  ];

  const createNewRequest = () => {
    setIsLoading(true);
    const documentLines = mainData
      .filter((item) => item.itemCode !== null)
      .map((v) => {
        if (get(v, 'itemCode', '')) {
          return {
            itemCode: get(v, 'itemCode', ''),
            itemDescription: get(v, 'itemName', ''),
            quantity: Number(get(v, 'quantity', '')),
            unitPrice: Number(get(v, 'unitPrice', '')),
            warehouseCode: get(v, 'warehouseCode', ''),
          };
        }
        return null;
      })
      .filter((v) => v !== null);

    let allData = {
      docDate: new Date().toISOString(),
      docDueDate: new Date().toISOString(),
      cardCode: get(user, 'cardCode', ''),
      cardName: get(user, 'cardName', ''),
      docCurrency: valuta,
      client: userName,
      number: userPhone,
      documentLines,
    };

    console.log(allData);

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`orders`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const fetchOrders = async ({ queryKey }) => {
    const [_, titleS, priceListNum, wCode, page] = queryKey;
    // console.log(priceListNum, wCode);

    let url =
      titleS.length > 0
        ? `items/by-warehouse/${wCode}/by-itemName/${titleS}/by-price-list/${priceListNum}/pagination/${page}`
        : `items/by-warehouse/${wCode}/by-price-list/${priceListNum}/pagination/${page}`;

    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(JSON.parse(response.data).data);
    return JSON.parse(response.data).data;
  };

  const { data: itemData, isLoading: itemsLoading } = useQuery(
    [
      'productModal',
      searchItemName,
      get(user, 'priceListNum', ''),
      get(wData, 'warehouseCode', ''),
      0,
    ],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const fetchWhs = async () => {
    const response = await api.get(`warehouse/pagination/0`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const {
    data: whsCode,
    isFetching,
    isError,
  } = useQuery(['warehouseModal'], () => fetchOrders(), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const addItem = () => {
    setMainData((pr) => [
      ...pr,
      {
        itemCode: '',
        itemName: '',
        quantity: '',
        unitPrice: '',
        warehouseCode: '',
        warehouseName: '',
      },
    ]);
  };

  const changeQuant = (i, quant) => {
    let changedData = [...mainData];
    changedData[i].quantity = quant;
    setMainData(changedData);
  };

  const changeItemName = (i, value) => {
    const val = value;
    const updatedItemName = get(val, 'itemName', '') || val;
    setSearchItemName(updatedItemName);

    const changedData = [...mainData];
    changedData[i] = {
      ...changedData[i],
      itemName: updatedItemName,
    };
    setMainData(changedData);
  };

  const handleSelect = (i, value) => {
    const val = JSON.parse(value);
    console.log('val = ', val);
    setSearchItemName(val.itemName);

    const changedData = [...mainData];
    changedData[i] = {
      ...changedData[i],
      itemCode: get(val, 'itemCode', ''),
      itemName: get(val, 'itemName', ''),
      unitPrice: get(val, 'price', ''),
      warehouseCode: get(wData, 'warehouseCode', ''),
      warehouseName: get(wData, 'warehouseName', ''),
    };
    setMainData(changedData);
  };

  const changePrice = (i, price_item) => {
    let changedData = [...mainData];
    changedData[i].unitPrice = price_item;
    setMainData(changedData);
  };

  const changeWhs = (i, whs) => {
    let changedData = [...mainData];
    changedData[i].warehouseCode = whs;
    setMainData(changedData);
  };

  const remove = (i) => {
    let changedData = [...mainData];
    changedData.splice(i, 1);
    setMainData(changedData);
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Button onClick={() => clientsRef.current?.open()} minW={'200px'}>
              {get(user, 'cardName', '')}
            </Button>
            <Button onClick={() => warehouseRef.current?.open()} minW={'200px'}>
              {get(wData, 'warehouseName', '')}
            </Button>
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={userName}
              onChange={(v) => setUserName(v.target.value)}
              borderRadius={'15px'}
              color={btnTextColor}
            />
            <Input
              placeholder={`${t('Ism kiriting')}`}
              value={userPhone}
              onChange={(v) => setUserPhone(v.target.value)}
              borderRadius={'15px'}
              color={btnTextColor}
            />
            <Input
              value={`${t('Mijoz balansi')} : ${get(
                user,
                'currentAccountBalance',
                '',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Box
              display={'flex'}
              flexWrap={'wrap'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              {typeOfValuta.map((item, index) => {
                return (
                  <Button
                    key={index}
                    backgroundColor={
                      item.val === valuta ? 'brand.300' : btnDefault
                    }
                    onClick={() => setValuta(item.val)}
                    width={'47%'}
                    color={item.val === valuta ? 'white' : textDefault}
                  >
                    {item.title}
                  </Button>
                );
              })}
            </Box>
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    width={'20px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    №
                  </Th>
                  <Th
                    width={'20px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    {t('Mahsulot')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Narxi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Ombor')}
                  </Th>
                  <Th
                    width={'20px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    {t('Del')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        width={'30px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {index + 1}
                      </Td>
                      <Td
                        width={'300px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <AutoComplete
                          loading={itemsLoading}
                          options={itemData?.map((item) => ({
                            value: JSON.stringify(item), // Store the full object as a JSON string
                            label: (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                }}
                              >
                                <span style={{ fontSize: '12px' }}>
                                  {item.itemName}
                                </span>
                                <Tag color="blue">{item.onHand}</Tag>
                              </div>
                            ), // Use Ant Design's Tag for styling
                          }))}
                          style={{ width: '350px' }}
                          value={get(v, 'itemName', '')} // Show the current item name
                          onChange={(val) => {
                            changeItemName(index, val); // Update state when typing
                          }}
                          onSelect={(val) => {
                            handleSelect(index, val); // Update state when selecting an item
                          }}
                        />
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        onClick={() => {
                          productsInWhsRef.current?.open(
                            get(v, 'itemCode', ''),
                          );
                        }}
                      >
                        {get(v, 'itemName', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'quantity', '')}
                          onChange={(title) =>
                            changeQuant(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'unitPrice', '')}
                          onChange={(title) =>
                            changePrice(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(wData, 'warehouseName', '')}
                        {/* <Select
                          minW={'200px'}
                          onChange={(e) => {
                            changeWhs(index, e.target.value);
                          }}
                          value={get(v, 'warehouseName', '')}
                        >
                          <option value={null}></option>
                          {whsCode?.map((cash) => {
                            return (
                              <option value={cash.acc}>{cash.name}</option>
                            );
                          })}
                        </Select> */}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        width={'20px'}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        textAlign={'center'}
                      >
                        <IconButton
                          onClick={() => remove(index)}
                          _hover={false}
                          backgroundColor={'red'}
                          icon={
                            <DeleteIcon color={'white'} w="13px" h="13px" />
                          }
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          <Box justifyContent={'space-between'} display={'flex'} mt={'20px'}>
            <IconButton
              onClick={addItem}
              _hover={false}
              backgroundColor={'brand.200'}
              icon={<AddIcon color={'white'} w="10px" h="10px" />}
            />
            <Flex gap={3}>
              <Input
                placeholder={`${t('docTotal')}`}
                value={
                  docTotal
                    ? `${t('docTotal')} ${new Intl.NumberFormat().format(
                        docTotal,
                      )}  ${valuta}`
                    : ''
                }
                width={300}
                borderRadius={'15px'}
                color={btnTextColor}
                disabled={true}
              />
              <Button
                isLoading={isLoading}
                onClick={createNewRequest}
                width={200}
                backgroundColor="green.500"
              >
                {t('Yaratish')}
              </Button>
            </Flex>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      <ProductModal
        getRef={(r) => (productRef.current = r)}
        // onConfirm={(item) => addItem(item)}
      />
      <ProductsInWarehouseModal
        getRef={(r) => (productsInWhsRef.current = r)}
      />
      <WarehouseModal
        getRef={(r) => (warehouseRef.current = r)}
        onConfirm={(item) => setwData(item)}
      />
      <ClientsModal
        getRef={(r) => (clientsRef.current = r)}
        onConfirm={(v) => {
          setUser(v);
          console.log(v);
        }}
      />
    </Style>
  );
}
