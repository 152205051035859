import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Box,
  Input,
  useColorModeValue,
  SimpleGrid,
  Select,
} from '@chakra-ui/react';
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import ClientsModal from '../ClientsModal';
import time from '../../../helpers/time';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import api from 'api';

const AddOutgoingPayment = ({
  getRef,
  isClientShow = false,
  isTypesShow = false,
  isDocTotalaShow = false,
  isShowDate = true,
  onConfirm = () => {},
}) => {
  const { getMe, token } = useSelector((state) => state.main);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [currency, setCurrency] = useState('');
  const [docDate, setDocDate] = useState(time.current('YYYY-MM-DD'));
  const [docRate, setDocRate] = useState('');
  const [incomingCash, setIncomingCash] = useState(
    get(getMe, 'uExpenditureAccount', ''),
  );
  const [outgoingCash, setOutgoingCash] = useState('');
  const [cashSum, setCashSum] = useState('');

  const tableHead = useColorModeValue('brand.300', 'white');
  const btnTextColor = useColorModeValue('black', 'white');

  useEffect(() => {
    const ref = {
      open: (data) => {
        setIsOpen(true);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const fetchDocRate = async ({ queryKey }) => {
    const [_, date] = queryKey;

    let url = `currency-rates/by-date`;

    const response = await api.post(
      url,
      {
        currency: 'UZS',
        date: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const {
    data,
    isLoading: docRateLoading,
    isError,
  } = useQuery(['getCurrentRate', time.current('YYYY-MM-DD')], fetchDocRate, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setDocRate(resData);
    }
  }, [data]);

  const onClose = () => {
    setIsOpen(false);
  };

  const cashAccs = [
    {
      acc: get(getMe, 'uDollarAccount', ''),
      name: t('dollarCash'),
      val: 'USD',
    },
    {
      acc: get(getMe, 'uAccount', ''),
      name: t('uzsCash'),
      val: 'UZS',
    },
    {
      acc: get(getMe, 'uCartAccount', ''),
      name: t('cardCash'),
      val: 'UZS',
    },
    {
      acc: get(getMe, 'uBankAccount', ''),
      name: t('bankCash'),
      val: 'UZS',
    },
  ];
  const accept = () => {
    setIsOpen(false);
    onConfirm(docDate, incomingCash, outgoingCash, cashSum, currency, docRate);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto">
        <ModalHeader>{t('addOutgoingPayment')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mb="20px">
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('docDate')}
              </Text>
              <Input
                value={`${docDate}`}
                borderRadius={'15px'}
                color={btnTextColor}
                onChange={(e) => {
                  setDocDate(e.target.value);
                }}
                type="date"
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('docRate')}
              </Text>
              <Input
                value={`${docRate}`}
                borderRadius={'15px'}
                color={btnTextColor}
                onChange={(e) => {
                  setDocRate(e.target.value);
                }}
                _loading={docRateLoading}
                type="number"
              />
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mb="20px">
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('outgoingCash')}
              </Text>
              <Select
                minW={'200px'}
                onChange={(e) => {
                  setOutgoingCash(e.target.value);
                  setCurrency(
                    e.target.value === get(getMe, 'uDollarAccount', '')
                      ? 'USD'
                      : 'UZS',
                  );
                }}
                value={outgoingCash}
              >
                <option value={null}></option>
                {cashAccs
                  .filter((cash) => {
                    return cash.acc !== incomingCash;
                  })
                  .map((cash) => {
                    return <option value={cash.acc}>{cash.name}</option>;
                  })}
              </Select>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('incomingCash')}
              </Text>
              <Select
                minW={'200px'}
                onChange={(e) => {
                  setIncomingCash(e.target.value);
                }}
                value={incomingCash}
              >
                <option value={get(getMe, 'uExpenditureAccount', '')}>
                  {t('expenditureCash')}
                </option>
              </Select>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mb="20px">
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('cashSum')}
              </Text>
              <Input
                value={cashSum}
                borderRadius={'15px'}
                color={btnTextColor}
                onChange={(e) => {
                  setCashSum(e.target.value);
                }}
                type="number"
              />
            </Box>
          </SimpleGrid>
        </ModalBody>
        <ModalFooter display={'flex'} justifyContent={'space-between'}>
          <Button backgroundColor="red.600" onClick={onClose}>
            {t('Bekor qilish')}
          </Button>
          <Button
            onClick={accept}
            backgroundColor="green.500"
            // isDisable={!valuta || !currency || !summa}
          >
            {t('add')}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddOutgoingPayment;
