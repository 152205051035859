import { ArrowLeftIcon, ArrowRightIcon, AddIcon } from '@chakra-ui/icons';
import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import api from 'api';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Input from '../../../components/Input';
import Style from './Style';
import { get } from 'lodash';
import CreateClientsModal from '../CreateClientsModal';

const ClientsModal = ({ getRef, onConfirm = () => {} }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [priceListNum, setPriceListNum] = useState(1);
  const [uName, setuName] = useState('');

  const clientsRef = useRef();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');

  const { token, getMe } = useSelector((state) => state.main);

  const uWarehouse = get(getMe, 'uWarehouse', '');

  useEffect(() => {
    const ref = {
      open: (num = 1) => {
        setIsOpen(true);
        setPriceListNum(num);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const fetchOrders = async ({ queryKey }) => {
    const [_, uName, page] = queryKey;

    let url =
      uName.length > 0
        ? `business-partners/search-by-card-name/${uName}/pagination/${page}`
        : `business-partners/pagination/${page}`;

    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['clientsModal', uName, page],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  const selectProduct = (selectedValue) => {
    onConfirm(selectedValue);
    setIsOpen(false);
  };

  if (isError) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx="auto" my="auto" maxW={900}>
          <ModalHeader>{t('Xatolik')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Style>
              <Box overflow={'auto'} mb={'15px'}>
                <Box mb={'20px'} className="flex">
                  <Input
                    isLoading={isFetching}
                    width={'200px'}
                    borderRadius={'15px'}
                    me={'20px'}
                    p={0}
                    value={uName}
                    onChange={(v) => setuName(v.target.value)}
                  />
                  <Box width={'100'} display={'flex'} alignItems={'center'}>
                    <IconButton
                      isLoading={isFetching}
                      me={'10px'}
                      _hover={false}
                      backgroundColor={'brand.200'}
                      icon={<ArrowLeftIcon color={'white'} w="10px" h="10px" />}
                      onClick={() => setPage((p) => p - 20)}
                      isDisabled={page < 1}
                    />
                    <Text>{page / 20 + 1}</Text>
                    <IconButton
                      onClick={() => setPage((p) => p + 20)}
                      isLoading={isFetching}
                      ms={'10px'}
                      _hover={false}
                      backgroundColor={'brand.200'}
                      icon={
                        <ArrowRightIcon color={'white'} w="10px" h="10px" />
                      }
                    />
                  </Box>
                </Box>
                <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
                  {t('Malumotlarni yuklab olishni iloji bolmadi')}
                </Text>
              </Box>
            </Style>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto" width="90%" maxW={900}>
        <ModalHeader>{t('Mijoz tanlang')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Style>
            <Box mb={'15px'}>
              <Box mb={'20px'} className="flex">
                <Box className="flex">
                  <Input
                    isLoading={isFetching}
                    width={'200px'}
                    borderRadius={'15px'}
                    me={'20px'}
                    p={0}
                    value={uName}
                    onChange={(v) => setuName(v.target.value)}
                  />
                  <IconButton
                    _hover={false}
                    backgroundColor={'brand.200'}
                    icon={<AddIcon color={'white'} w="10px" h="10px" />}
                    onClick={() => clientsRef.current?.open()}
                  />
                </Box>
                <Box width={'100'} display={'flex'} alignItems={'center'}>
                  <IconButton
                    isLoading={isFetching}
                    me={'10px'}
                    _hover={false}
                    backgroundColor={'brand.200'}
                    icon={<ArrowLeftIcon color={'white'} w="10px" h="10px" />}
                    onClick={() => setPage((p) => p - 20)}
                    isDisabled={page < 1}
                  />
                  <Text>{page / 20 + 1}</Text>
                  <IconButton
                    onClick={() => setPage((p) => p + 20)}
                    isLoading={isFetching}
                    ms={'10px'}
                    _hover={false}
                    backgroundColor={'brand.200'}
                    icon={<ArrowRightIcon color={'white'} w="10px" h="10px" />}
                  />
                </Box>
              </Box>
              <Box height={'50vh'} overflow={'auto'}>
                <Table
                  variant={'unstyled'}
                  color="gray.500"
                  position={'relative'}
                >
                  <Thead>
                    <Tr>
                      <Th
                        px={'10px'}
                        border="1px"
                        borderColor="gray.200"
                        color={tableHead}
                      >
                        {t('Ism')}
                      </Th>
                      <Th
                        px={'10px'}
                        border="1px"
                        borderColor="gray.200"
                        color={tableHead}
                      >
                        {t('Balans')}
                      </Th>
                      <Th
                        px={'10px'}
                        border="1px"
                        borderColor="gray.200"
                        color={tableHead}
                      >
                        {t('Guruh kodi')}
                      </Th>
                      <Th
                        px={'10px'}
                        border="1px"
                        borderColor="gray.200"
                        color={tableHead}
                      >
                        {t('Telefon raqam')}
                      </Th>
                      <Th
                        px={'10px'}
                        border="1px"
                        borderColor="gray.200"
                        color={tableHead}
                      >
                        {t('Manzil')}
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {mainData.map((value, index) => {
                      return (
                        <Tr
                          key={index}
                          onClick={() => selectProduct(value)}
                          className="tr"
                          _hover={{
                            bg: bgTr,
                          }}
                        >
                          <Td
                            border="1px"
                            borderColor="gray.200"
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            px={'10px'}
                            py={'5px'}
                            color={tablBody}
                          >
                            {get(value, 'cardName', '')}
                          </Td>
                          <Td
                            border="1px"
                            borderColor="gray.200"
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            px={'10px'}
                            py={'5px'}
                            color={tablBody}
                          >
                            {get(value, 'currentAccountBalance', '')}
                          </Td>
                          <Td
                            border="1px"
                            borderColor="gray.200"
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            px={'10px'}
                            py={'5px'}
                            color={tablBody}
                          >
                            {get(value, 'groupCode', '')}
                          </Td>
                          <Td
                            border="1px"
                            borderColor="gray.200"
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            px={'10px'}
                            py={'5px'}
                            color={tablBody}
                          >
                            {get(value, 'phone1', '')}
                          </Td>
                          <Td
                            border="1px"
                            borderColor="gray.200"
                            fontSize={{ sm: '14px' }}
                            minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                            px={'10px'}
                            py={'5px'}
                            color={tablBody}
                          >
                            {get(value, 'region', '')}
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </Box>
            </Box>
          </Style>
        </ModalBody>
      </ModalContent>
      <CreateClientsModal
        getRef={(r) => (clientsRef.current = r)}
        // onConfirm={(v) => setUser(v)}
      />
    </Modal>
  );
};

export default ClientsModal;
