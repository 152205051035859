import styled from 'styled-components';

const Style = styled.div`
  .topCard {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 20px;
  }
`;

export default Style;
