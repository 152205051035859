import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddIcon, ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import Card from 'components/card/Card';
import Button from '../../../components/Button';
import time from 'helpers/time';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import ErrorModal from 'components/Modals/ErrorModal';
import SuccessModal from 'components/Modals/SuccessModal';
import api from 'api';

export default function ViewPurchaseSHOP() {
  const { t } = useTranslation();

  const navigation = useNavigate();

  const errorRef = useRef();
  const successRef = useRef();

  const { getMe, token } = useSelector((state) => state.main);
  const uWarehouse = get(getMe, 'uWarehouse', '');
  const salesPersonCode = get(getMe, 'salesPersonCode', '');

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const btnTextColor = useColorModeValue('black', 'white');

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const data = get(location, 'state', {});
  const mainData = get(data, 'documentLines', []);

  const cancelItem = () => {
    setIsLoading(true);
    const documentLines = mainData.map((v, i) => {
      return {
        itemCode: get(v, 'itemCode', ''),
        itemDescription: get(v, 'itemDescription', ''),
        quantity: get(v, 'quantity', ''),
        unitPrice: get(v, 'unitPrice', ''),
        baseType: 18,
        baseEntry: get(data, 'docEntry', ''),
        baseLine: get(v, 'lineNum', ''),
        warehouseCode: get(v, 'warehouseCode', ''),
      };
    });

    let allData = {
      docDate: new Date().toISOString(),
      docDueDate: new Date().toISOString(),
      cardCode: get(data, 'cardCode', ''),
      cardName: get(data, 'cardName', ''),
      docCurrency: get(data, 'docCurrency', ''),
      salesPersonCode: salesPersonCode,
      documentLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(`purchase-credit-notes`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Input
              value={`№ ${get(data, 'docNum', '')}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Ism')} : ${get(data, 'cardName', '')}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Sana')} : ${time.to(
                get(data, 'docDate', ''),
                'DD-MM-YYYY',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Sum')} : ${get(data, 'docTotal', '')} ${get(
                data,
                'docCurrency',
                '',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Narxi')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemDescription', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'quantity', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'unitPrice', '')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={cancelItem}
              width={200}
              backgroundColor="red"
            >
              {t('Отменить')}
            </Button>
          </Box>
        </Card>
      </Box>
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
    </>
  );
}
