import React from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';

export default function ViewPurchasereportSHOP() {
  const { t } = useTranslation();

  const navigation = useNavigate();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const btnTextColor = useColorModeValue('black', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const documentLines = get(data, 'documentLines', []);

  return (
    <>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <Text
            fontSize={'30px'}
            textAlign={'center'}
            color={tableHead}
            mb={'10px'}
          >
            {`№ ${get(data, 'docNum', '')}`} {t('Umumiy savdo')}
          </Text>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <Input
              value={`${t('Ism')} : ${get(data, 'cardName', '')}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Sana')} : ${time.to(
                get(data, 'docDate', ''),
                'DD-MM-YYYY',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
            <Input
              value={`${t('Sum')} : ${get(data, 'docTotal', '')} ${get(
                data,
                'docCurrency',
                '',
              )}`}
              borderRadius={'15px'}
              color={btnTextColor}
              disabled={true}
            />
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    №
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Narxi')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {documentLines.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemCode', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemDescription', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'quantity', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'unitPrice', '')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
        </Card>
      </Box>
    </>
  );
}
