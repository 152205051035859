import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import {
  Box,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import api from 'api';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import Input from '../../../components/Input';
import ProductStyle from './ProductStyle';
import { get } from 'lodash';

const WarehouseModal = ({ getRef, onConfirm = () => {} }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(0);

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');

  const { token, getMe } = useSelector((state) => state.main);

  const uWarehouse = get(getMe, 'uWarehouse', '');

  useEffect(() => {
    const ref = {
      open: () => {
        setIsOpen(true);
        fetchOrders(0);
      },
      close: () => setIsOpen(false),
    };
    getRef(ref);
  }, []);

  const onClose = () => {
    setIsOpen(false);
  };

  const fetchOrders = async (skip = 0) => {
    const response = await api.get(`warehouse/pagination/${skip}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['warehouseModal', page],
    () => fetchOrders(page),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  const selectProduct = (selectedValue) => {
    onConfirm(selectedValue);
    setIsOpen(false);
  };

  if (isError) {
    return (
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent mx="auto" my="auto" maxW={900}>
          <ModalHeader>{t('Ombor tanlang')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <ProductStyle>
              <Box overflow={'auto'} mb={'15px'}>
                <Box mb={'20px'} className="flex">
                  <Input
                    isLoading={isFetching}
                    width={'200px'}
                    borderRadius={'15px'}
                    me={'20px'}
                    p={0}
                    onConfirm={() => console.log('salom')}
                  />
                  <Box width={'100'} display={'flex'} alignItems={'center'}>
                    <IconButton
                      isLoading={isFetching}
                      me={'10px'}
                      _hover={false}
                      backgroundColor={'brand.200'}
                      icon={<ArrowLeftIcon color={'white'} w="10px" h="10px" />}
                      onClick={() => setPage((p) => p - 20)}
                      isDisabled={page < 1}
                    />
                    <Text>{page / 20 + 1}</Text>
                    <IconButton
                      onClick={() => setPage((p) => p + 20)}
                      isLoading={isFetching}
                      ms={'10px'}
                      _hover={false}
                      backgroundColor={'brand.200'}
                      icon={
                        <ArrowRightIcon color={'white'} w="10px" h="10px" />
                      }
                    />
                  </Box>
                </Box>
                <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
                  {t('Malumotlarni yuklab olishni iloji bolmadi')}
                </Text>
              </Box>
            </ProductStyle>
          </ModalBody>
        </ModalContent>
      </Modal>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent mx="auto" my="auto" width="90%" maxW={900}>
        <ModalHeader>{t('Ombor tanlang')}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <ProductStyle>
            <Box overflow={'auto'} mb={'15px'}>
              <Box mb={'20px'} className="flex">
                <Box width={'100'} display={'flex'} alignItems={'center'}>
                  <IconButton
                    isLoading={isFetching}
                    me={'10px'}
                    _hover={false}
                    backgroundColor={'brand.200'}
                    icon={<ArrowLeftIcon color={'white'} w="10px" h="10px" />}
                    onClick={() => setPage((p) => p - 20)}
                    isDisabled={page < 1}
                  />
                  <Text>{page / 20 + 1}</Text>
                  <IconButton
                    onClick={() => setPage((p) => p + 20)}
                    isLoading={isFetching}
                    ms={'10px'}
                    _hover={false}
                    backgroundColor={'brand.200'}
                    icon={<ArrowRightIcon color={'white'} w="10px" h="10px" />}
                  />
                </Box>
              </Box>
              <Table
                variant={'unstyled'}
                color="gray.500"
                position={'relative'}
              >
                <Thead>
                  <Tr>
                    <Th
                      px={'10px'}
                      border="1px"
                      borderColor="gray.200"
                      color={tableHead}
                    >
                      {t('Kod')}
                    </Th>
                    <Th
                      px={'10px'}
                      border="1px"
                      borderColor="gray.200"
                      color={tableHead}
                    >
                      {t('Nomi')}
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {mainData.map((value, index) => {
                    return (
                      <Tr
                        key={index}
                        onClick={() => selectProduct(value)}
                        className="tr"
                        _hover={{
                          bg: bgTr,
                        }}
                      >
                        <Td
                          width={'100px'}
                          cursor="pointer"
                          border="1px"
                          borderColor="gray.200"
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          px={'10px'}
                          py={'5px'}
                          color={tablBody}
                        >
                          {get(value, 'warehouseCode', '')}
                        </Td>
                        <Td
                          width={'100px'}
                          cursor="pointer"
                          border="1px"
                          borderColor="gray.200"
                          fontSize={{ sm: '14px' }}
                          minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                          px={'10px'}
                          py={'5px'}
                          color={tablBody}
                        >
                          {get(value, 'warehouseName', '')}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </Box>
          </ProductStyle>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WarehouseModal;
