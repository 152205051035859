import {
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
  Text,
  Spinner,
  Box,
} from '@chakra-ui/react';

import Card from 'components/card/Card.js';

import React from 'react';

export default function Default(props) {
  const {
    startContent,
    endContent,
    name,
    growth,
    value,
    isLoading = false,
  } = props;
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const spinnercolor = useColorModeValue('brand.300', 'brand.200');
  const textColorSecondary = 'secondaryGray.600';

  return (
    <Card py="15px">
      {isLoading ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color={spinnercolor}
            size="xl"
          />
        </Box>
      ) : (
        <Flex
          my="auto"
          h="100%"
          align={{ base: 'center', xl: 'start' }}
          justify={{ base: 'center', xl: 'center' }}
        >
          {startContent}

          <Stat my="auto" ms={startContent ? '18px' : '0px'}>
            <StatLabel
              lineHeight="100%"
              color={textColorSecondary}
              fontSize={{
                base: 'sm',
              }}
            >
              {name}
            </StatLabel>
            <StatNumber
              color={textColor}
              fontSize={{
                base: '2xl',
              }}
            >
              {value}
            </StatNumber>
            {growth ? (
              <Flex align="center">
                <Text color="green.500" fontSize="xs" fontWeight="700" me="5px">
                  {growth}
                </Text>
                <Text color="secondaryGray.600" fontSize="xs" fontWeight="400">
                  since last month
                </Text>
              </Flex>
            ) : null}
          </Stat>
          <Flex ms="auto" w="max-content">
            {endContent}
          </Flex>
        </Flex>
      )}
    </Card>
  );
}
