import React, { useEffect, useState } from 'react';
import {
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import Style from './Style';
import { get } from 'lodash';
import api from 'api';

export default function Stock() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { token } = useSelector((state) => state.main);

  const [page, setPage] = useState(0);

  const searchIconColor = useColorModeValue('white');
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');

  const fetchOrders = async (skip = 0) => {
    const response = await api.get(`item-groups/pagination/${skip}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isError, isFetching } = useQuery(
    ['stock', page],
    () => fetchOrders(page),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <Box mb={'20px'} className={'flex'}>
              <Input
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                onConfirm={() => console.log('salom')}
              />
              <Box width={'100'} display={'flex'} alignItems={'center'}>
                <IconButton
                  isLoading={isFetching}
                  me={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                  }
                  onClick={() => setPage((p) => p - 20)}
                  isDisabled={page < 1}
                />
                <Text>{page / 20 + 1}</Text>
                <IconButton
                  onClick={() => setPage((p) => p + 20)}
                  isLoading={isFetching}
                  ms={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                  }
                />
              </Box>
            </Box>
            <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
              {t('Malumotlarni yuklab olishni iloji bolmadi')}
            </Text>
          </Card>
        </Box>
      </Style>
    );
  }

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <Box mb={'20px'} className="flex">
            <Box display={'flex'} alignItems={'center'}>
              <IconButton
                isLoading={isFetching}
                me={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                }
                onClick={() => setPage((p) => p - 20)}
                isDisabled={page < 1}
              />
              <Text>{page / 20 + 1}</Text>
              <IconButton
                onClick={() => setPage((p) => p + 20)}
                isLoading={isFetching}
                ms={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                }
              />
            </Box>
          </Box>
          <Table variant={'unstyled'} color="gray.500" position={'relative'}>
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  px={'10px'}
                >
                  {t('Guruh raqami')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Guruh nomi')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mainData.map((value, index) => {
                return (
                  <Tr
                    key={index}
                    onClick={() =>
                      navigation('stockMiniGroup', { state: value })
                    }
                    className="tr"
                    _hover={{
                      bg: bgTr,
                    }}
                  >
                    <Td
                      width={'100px'}
                      cursor="pointer"
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'number', '')}
                    </Td>
                    <Td
                      width={'100px'}
                      cursor="pointer"
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'groupName', '')}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Card>
      </Box>
    </Style>
  );
}
