import React, { useEffect, useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  Table,
  Tbody,
  Thead,
  Text,
  Box,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { AddIcon } from '@chakra-ui/icons';
import Style from './Style';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';

export default function SalesSHOP() {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { token } = useSelector((state) => state.main);

  const [page, setPage] = useState(0);
  const [userName, setUserName] = useState('');
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const searchIconColor = useColorModeValue('white');
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');

  const fetchOrders = async ({ queryKey }) => {
    const [_, userName, startDate, endDate, page] = queryKey;

    let startUrl = 'invoices/';
    let endUrl = `pagination/${page}`;
    let url = '';

    if (userName.length > 0) {
      startUrl = startUrl + `card-name/${userName}/`;
    } else if (startDate.length > 0 && endDate.length > 0) {
      startUrl = startUrl + `between-doc-date/${startDate}/${endDate}/`;
    }

    url = startUrl + endUrl;
    console.log(url);

    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['salesOrderSHOP', userName, startDate, endDate, page],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <Box mb={'20px'} className="flex">
              <Input
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                onConfirm={() => console.log('salom')}
              />
              <Box width={'100'} display={'flex'} alignItems={'center'}>
                <IconButton
                  isLoading={isFetching}
                  me={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                  }
                  onClick={() => setPage((p) => p - 20)}
                  isDisabled={page < 1}
                />
                <Text>{page / 20 + 1}</Text>
                <IconButton
                  onClick={() => setPage((p) => p + 20)}
                  isLoading={isFetching}
                  ms={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                  }
                />
              </Box>
            </Box>
            <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
              {t('Malumotlarni yuklab olishni iloji bolmadi')}
            </Text>
          </Card>
        </Box>
      </Style>
    );
  }

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card py="20px" border="1px" borderColor="gray.200">
          <Box mb={'20px'} className="flex">
            <Box display={'flex'}>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Ism')}
                </Text>
                <Input
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={userName}
                  onChange={(v) => setUserName(v.target.value)}
                />
              </div>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Boshlanish sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={startDate}
                  onChange={(v) => setStartDate(v.target.value)}
                />
              </div>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Tugash sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={endDate}
                  onChange={(v) => setEndDate(v.target.value)}
                />
              </div>
            </Box>
            <Box width={'100'} display={'flex'} alignItems={'center'}>
              <IconButton
                isLoading={isFetching}
                me={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                }
                onClick={() => setPage((p) => p - 20)}
                isDisabled={page < 1}
              />
              <Text>{page / 20 + 1}</Text>
              <IconButton
                onClick={() => setPage((p) => p + 20)}
                isLoading={isFetching}
                ms={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                }
              />
            </Box>
          </Box>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500" position={'relative'}>
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    width={'50px'}
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    textAlign={'center'}
                  >
                    {'№'}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Ism')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('debt')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('paidToDate')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Общая цена')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Sana')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((value, index) => {
                  return (
                    <Tr
                      cursor="pointer"
                      key={index}
                      onClick={() => navigation('viewSSHOP', { state: value })}
                      className="tr"
                      _hover={{
                        bg: bgTr,
                      }}
                    >
                      <Td
                        width={'50px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        textAlign={'center'}
                      >
                        {get(value, 'docNum', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'cardName', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'docTotal', '') -
                          get(value, 'paidToDate', '')}{' '}
                        {get(value, 'docCurrency', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'paidToDate', '')}{' '}
                        {get(value, 'docCurrency', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'docTotal', '')}{' '}
                        {get(value, 'docCurrency', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {time.to(get(value, 'docDate', ''), 'DD-MM-YYYY')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Box justifyContent={'flex-end'} display={'flex'} mt={'20px'}>
            <IconButton
              onClick={() => navigation('createSSHOP')}
              ms={'10px'}
              _hover={false}
              backgroundColor={'brand.200'}
              icon={<AddIcon color={searchIconColor} w="10px" h="10px" />}
            />
          </Box>
        </Card>
      </Box>
    </Style>
  );
}
