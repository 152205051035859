import React from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { get } from 'lodash';
import LinkStyle from './LinkStyle';
import { useTranslation } from 'react-i18next';
import { main } from 'store/slices';
import { useDispatch } from 'react-redux';

export function SidebarLinks(props) {
  const { t } = useTranslation();
  let location = useLocation();
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue(
    'secondaryGray.700',
    'secondaryGray.700',
  );
  let activeIcon = useColorModeValue('brand.500', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');

  const { routes } = props;

  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const dispatch = useDispatch();
  const { setHeaderScreenName } = main.actions;

  return (
    <LinkStyle>
      <Accordion allowMultiple width={'100%'}>
        {routes.map((v, i) => {
          return v.single ? (
            <NavLink
              key={i}
              to={v.layout + v.path}
              onClick={() => dispatch(setHeaderScreenName(t(v.mainName)))}
            >
              <HStack
                spacing={activeRoute(v.path.toLowerCase()) ? '22px' : '26px'}
                py="5px"
                ps="10px"
                display={'flex'}
                justifyContent={'space-between'}
              >
                <div className="iconCard">
                  <Box
                    color={
                      activeRoute(v.path.toLowerCase()) ? activeIcon : textColor
                    }
                    pt={1}
                  >
                    {v.icon}
                  </Box>
                  <Text
                    me="auto"
                    color={
                      activeRoute(v.path.toLowerCase())
                        ? activeColor
                        : inactiveColor
                    }
                    fontWeight={
                      activeRoute(v.path.toLowerCase()) ? 'bold' : 'normal'
                    }
                    className="text"
                  >
                    {t(v.mainName)}
                  </Text>
                </div>

                {activeRoute(v.path.toLowerCase()) ? (
                  <Box h="36px" w="4px" bg="brand.400" borderRadius="5px" />
                ) : (
                  <Box h="36px" w="4px" bg="brand.10" borderRadius="5px" />
                )}
              </HStack>
            </NavLink>
          ) : (
            <AccordionItem key={i}>
              <AccordionButton ps={3} pe={0} pb={2} color={inactiveColor}>
                {v.icon}
                <Box as="span" flex="1" textAlign="left" ps={5}>
                  {t(get(v, 'mainName', ''))}
                </Box>
                <AccordionIcon />
              </AccordionButton>
              {get(v, 'miniMenu', []).map((el, index) => {
                return (
                  <AccordionPanel pb={0} key={index}>
                    <Link
                      to={el.layout + el.path}
                      onClick={() => dispatch(setHeaderScreenName(t(el.name)))}
                    >
                      <HStack
                        spacing={
                          activeRoute(el.path.toLowerCase()) ? '22px' : '26px'
                        }
                        ps="10px"
                        display={'flex'}
                        justifyContent={'space-between'}
                        pb={1}
                      >
                        <div className="iconCard">
                          <Box
                            color={
                              activeRoute(el.path.toLowerCase())
                                ? activeIcon
                                : textColor
                            }
                            pt={1}
                          >
                            {el.icon}
                          </Box>
                          <Text
                            me="auto"
                            color={
                              activeRoute(el.path.toLowerCase())
                                ? activeColor
                                : inactiveColor
                            }
                            fontWeight={
                              activeRoute(el.path.toLowerCase())
                                ? 'bold'
                                : 'normal'
                            }
                            className="text"
                          >
                            {t(get(el, 'name', ''))}
                          </Text>
                        </div>
                        {activeRoute(el.path.toLowerCase()) ? (
                          <Box
                            h="36px"
                            w="4px"
                            bg="brand.400"
                            borderRadius="5px"
                          />
                        ) : (
                          <Box
                            h="36px"
                            w="4px"
                            bg="brand.10"
                            borderRadius="5px"
                          />
                        )}
                      </HStack>
                    </Link>
                  </AccordionPanel>
                );
              })}
            </AccordionItem>
          );
        })}
      </Accordion>
    </LinkStyle>
  );
}

export default SidebarLinks;
