import React, { useRef, useState, useEffect } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Input,
  Box,
  Flex,
  Select,
  Text,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import SuccessModal from 'components/Modals/SuccessModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';
import time from '../../../helpers/time';
import { useQuery } from 'react-query';

export default function Exchange() {
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);

  const errorRef = useRef();
  const successRef = useRef();

  const navigation = useNavigate();

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const btnTextColor = useColorModeValue('black', 'white');
  const btnDefault = useColorModeValue('gray.200', 'gray.500');
  const textDefault = useColorModeValue('black', 'White');

  const [isLoading, setIsLoading] = useState(false);
  const [docDate, setDocDate] = useState(time.current('YYYY-MM-DD'));
  const [docRate, setDocRate] = useState('');
  const [incomingCash, setIncomingCash] = useState('');
  const [outgoingCash, setOutgoingCash] = useState('');
  const [cashSum, setCashSum] = useState('');
  const [currency, setCurrency] = useState('');

  const cashAccs = [
    {
      acc: get(getMe, 'uDollarAccount', ''),
      name: t('dollarCash'),
      val: 'USD',
    },
    {
      acc: get(getMe, 'uAccount', ''),
      name: t('uzsCash'),
      val: 'UZS',
    },
    {
      acc: get(getMe, 'uCartAccount', ''),
      name: t('cardCash'),
      val: 'UZS',
    },
    {
      acc: get(getMe, 'uBankAccount', ''),
      name: t('bankCash'),
      val: 'UZS',
    },
  ];

  const createNewRequest = () => {
    setIsLoading(true);

    const incoming =
      cashAccs.find((cash) => cash.acc === incomingCash)?.val || null;
    const outgoing =
      cashAccs.find((cash) => cash.acc === outgoingCash)?.val || null;
    const status =
      (outgoing === 'UZS' && incoming === 'UZS') ||
      (outgoing === 'USD' && incoming === 'USD')
        ? '1'
        : outgoing === 'USD' && incoming === 'UZS'
        ? '2'
        : outgoing === 'UZS' && incoming === 'USD'
        ? '3'
        : '';
    console.log('incoming = ', incoming);
    console.log('outgoing = ', outgoing);
    console.log('status = ', status);

    let allData = {
      docType: 'rAccount',
      docDate: `${docDate}T11:26:08.963Z`,
      cardCode: outgoingCash,
      cashAccount: incomingCash,
      docCurrency: currency,
      cashSum: Number(cashSum),
      docRate: Number(docRate),
      paymentAccounts: [
        {
          accountCode: outgoingCash,
          sumPaid: Number(cashSum),
        },
      ],
    };

    console.log(allData);

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    api
      .post(
        `incoming-payments/status/${Number(
          status,
        )}/conversion-between-accounts`,
        allData,
        header,
      )
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const fetchDocRate = async ({ queryKey }) => {
    const [_, date] = queryKey;

    let url = `currency-rates/by-date`;

    const response = await api.post(
      url,
      {
        currency: 'UZS',
        date: date,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['getCurrentRate', time.current('YYYY-MM-DD')],
    fetchDocRate,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setDocRate(resData);
    }
  }, [data]);

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mb="20px">
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('docDate')}
              </Text>
              <Input
                value={`${docDate}`}
                borderRadius={'15px'}
                color={btnTextColor}
                onChange={(e) => {
                  setDocDate(e.target.value);
                }}
                type="date"
              />
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('docRate')}
              </Text>
              <Input
                value={`${docRate}`}
                borderRadius={'15px'}
                color={btnTextColor}
                onChange={(e) => {
                  setDocRate(e.target.value);
                }}
                type="number"
              />
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mb="20px">
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('outgoingCash')}
              </Text>
              <Select
                minW={'200px'}
                onChange={(e) => {
                  setOutgoingCash(e.target.value);
                  setCurrency(
                    e.target.value === get(getMe, 'uDollarAccount', '')
                      ? 'USD'
                      : 'UZS',
                  );
                }}
                value={outgoingCash}
              >
                <option value={null}></option>
                {cashAccs
                  .filter((cash) => {
                    return cash.acc !== incomingCash;
                  })
                  .map((cash) => {
                    return <option value={cash.acc}>{cash.name}</option>;
                  })}
              </Select>
            </Box>
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('incomingCash')}
              </Text>
              <Select
                minW={'200px'}
                onChange={(e) => {
                  setIncomingCash(e.target.value);
                }}
                value={incomingCash}
              >
                <option value={null}></option>
                {cashAccs
                  .filter((cash) => {
                    return cash.acc !== outgoingCash;
                  })
                  .map((cash) => {
                    return <option value={cash.acc}>{cash.name}</option>;
                  })}
              </Select>
            </Box>
          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px" mb="20px">
            <Box display={'flex'} flexDirection={'column'}>
              <Text color={tableHead} me={'5px'}>
                {t('cashSum')}
              </Text>
              <Input
                value={cashSum}
                borderRadius={'15px'}
                color={btnTextColor}
                onChange={(e) => {
                  setCashSum(e.target.value);
                }}
                type="number"
              />
            </Box>
          </SimpleGrid>

          <Box justifyContent={'space-between'} display={'flex'} mt={'20px'}>
            <Flex gap={3}>
              <Button
                isLoading={isLoading}
                onClick={createNewRequest}
                width={200}
                backgroundColor="green.500"
              >
                {t('Конвертация')}
              </Button>
            </Flex>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal
        getRef={(r) => (successRef.current = r)}
        isBack={false}
        onConfirm={() => {
          navigation('/admin/exchangehistoryshop');
        }}
      />
    </Style>
  );
}
