import React, { useState, useRef } from 'react';
import {
  useColorModeValue,
  IconButton,
  Table,
  Tbody,
  Thead,
  Box,
  Td,
  Th,
  Tr,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';
import AddIncomingPayment from '../../../components/Modals/AddIncomingPayment/index';
import ErrorModal from '../../../components/Modals/ErrorModal/index';
import SuccessModal from '../../../components/Modals/SuccessModal/index';
import { useQueryClient } from 'react-query';
import Button from '../../../components/Button';
import { useSelector } from 'react-redux';

export default function ViewOutgoingPaymentSHOP() {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.main);

  const queryClient = useQueryClient();

  const paymentRef = useRef();
  const errorRef = useRef();
  const successRef = useRef();
  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const redText = useColorModeValue('red', 'white');
  const greenText = useColorModeValue('green', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const stockTransferLines = get(data, 'documentLines', []);

  const [isLoading, setIsLoading] = useState(false);

  const createPayment = (date, user, acc, summa, currency, rate) => {
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const paymentInvoices = stockTransferLines.map((item, idx) => ({
      lineNum: idx,
      docEntry: get(data, 'docEntry', ''),
      sumApplied: summa,
      invoiceType: 'it_PurchaseInvoice',
    }));
    api
      .post(
        'outgoing-payments/based-on-purchase-invoice',
        {
          docType: 'rCustomer',
          cardCode: get(data, 'cardCode', ''),
          cashAccount: acc,
          cashSum: summa,
          docCurrency: currency,
          docRate: rate,
          invoiceCurrency: get(data, 'docCurrency', ''),
          paymentInvoices,
        },
        header,
      )
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
        queryClient.invalidateQueries(['incomingPaymentSHOP']);
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <Box mb={'20px'}>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {'№:'}
              </Text>
              <Text>{get(data, 'docNum', '')}</Text>
            </Box>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('Ism')}
                {':'}
              </Text>
              <Text>{get(data, 'cardName', '')}</Text>
            </Box>

            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('Дата создания')}
                {':'}
              </Text>
              <Text>{time.to(get(data, 'docDate', ''), 'DD-MM-YYYY')}</Text>
            </Box>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('docTotal')}
                {':'}
              </Text>
              <Text>{`${get(data, 'docTotal', '')} ${get(
                data,
                'docCurrency',
                '',
              )}`}</Text>
            </Box>
          </Box>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Цена')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Общая цена')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {stockTransferLines.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'itemDescription', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'unitPrice', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'quantity', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'lineTotal', '')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>
          <Box justifyContent={'end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={() =>
                paymentRef.current?.open({
                  docTotal: get(data, 'docTotal', ''),
                  currency: get(data, 'docCurrency', ''),
                })
              }
              width={200}
              backgroundColor="green.500"
            >
              {t('Tolov')}
            </Button>
          </Box>
        </Card>
      </Box>
      <AddIncomingPayment
        getRef={(r) => (paymentRef.current = r)}
        isTypesShow={true}
        isDocTotalaShow={true}
        isShowDate={false}
        onConfirm={(date, user, acc, summa, currency, rate) =>
          createPayment(date, user, acc, summa, currency, rate)
        }
      />
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} isBack={false} />
    </Style>
  );
}
