import React, { useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  Table,
  Tbody,
  Thead,
  Box,
  Td,
  Th,
  Tr,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useTranslation } from 'react-i18next';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import Style from './Style';

export default function ViewExchangeHistorySHOP() {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const redText = useColorModeValue('red', 'white');
  const greenText = useColorModeValue('green', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const paymentAccounts = get(data, 'paymentAccounts', []);

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <Box mb={'20px'}>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {'№:'}
              </Text>
              <Text>{get(data, 'docNum', '')}</Text>
            </Box>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('fromCash')}
                {':'}
              </Text>
              <Text>{get(data, 'cardCode', '')}</Text>
            </Box>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('toCash')}
                {':'}
              </Text>
              <Text>{get(data, 'cashAccount', '')}</Text>
            </Box>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('docTotal')}
                {':'}
              </Text>
              <Text>{get(data, 'cashSum', '')}</Text>
            </Box>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('Дата создания')}
                {':'}
              </Text>
              <Text>{time.to(get(data, 'docDate', ''), 'DD-MM-YYYY')}</Text>
            </Box>
          </Box>
          {/* <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('accountCode')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('paid')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {paymentAccounts.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'accountCode', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(v, 'sumPaid', '')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box> */}
        </Card>
      </Box>
    </Style>
  );
}
