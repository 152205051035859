import styled from 'styled-components';

const NavbarStyle = styled.div`
  .imgCard {
    display: flex;
    align-items: center;
    background: transparent;
    border: none;
    .flag_img {
      object-fit: cover;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      margin-right: 10px;
      border: 0.5px solid #e0e0e0;
    }
  }
`;
export default NavbarStyle;
