import React, { useEffect, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  Table,
  Tbody,
  Thead,
  Text,
  Box,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import Button from 'components/Button';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import time from 'helpers/time';
import { get } from 'lodash';
import Style from './Style';
import api from 'api';

export default function Debtors() {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { token } = useSelector((state) => state.main);

  const [page, setPage] = useState(0);
  const [userName, setUserName] = useState('');

  const searchIconColor = useColorModeValue('white');
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');
  const redText = useColorModeValue('red', 'white');
  const greenText = useColorModeValue('green', 'white');

  const fetchOrders = async ({ queryKey }) => {
    const [_, userName, page] = queryKey;

    let startUrl = 'business-partners/debitors/';
    let endUrl = `pagination/${page}`;
    let url = '';

    if (userName.length > 0) {
      startUrl = startUrl + `by-card-name/${userName}/`;
    }

    url = startUrl + endUrl;

    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['debtors', userName, page],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Style>
        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <Box mb={'20px'} className="flex">
            <Input
              isLoading={isFetching}
              width={'200px'}
              borderRadius={'15px'}
              me={'20px'}
              p={0}
              onConfirm={() => console.log('salom')}
            />
            <Box width={'100'} display={'flex'} alignItems={'center'}>
              <IconButton
                isLoading={isFetching}
                me={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                }
                onClick={() => setPage((p) => p - 20)}
                isDisabled={page < 1}
              />
              <Text>{page / 20 + 1}</Text>
              <IconButton
                onClick={() => setPage((p) => p + 20)}
                isLoading={isFetching}
                ms={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                }
              />
            </Box>
          </Box>
          <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
            {t('Malumotlarni yuklab olishni iloji bolmadi')}
          </Text>
        </Card>
      </Style>
    );
  }

  return (
    <Style>
      <Box mb={'20px'} className="flex">
        <Input
          isLoading={isFetching}
          width={'200px'}
          borderRadius={'15px'}
          me={'20px'}
          p={0}
          value={userName}
          onChange={(v) => setUserName(v.target.value)}
        />
        <Box width={'100'} display={'flex'} alignItems={'center'}>
          <IconButton
            isLoading={isFetching}
            me={'10px'}
            _hover={false}
            backgroundColor={'brand.200'}
            icon={<ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />}
            onClick={() => setPage((p) => p - 20)}
            isDisabled={page < 1}
          />
          <Text>{page / 20 + 1}</Text>
          <IconButton
            onClick={() => setPage((p) => p + 20)}
            isLoading={isFetching}
            ms={'10px'}
            _hover={false}
            backgroundColor={'brand.200'}
            icon={<ArrowRightIcon color={searchIconColor} w="10px" h="10px" />}
          />
        </Box>
      </Box>
      <Box overflow="auto">
        <Table variant={'unstyled'} color="gray.500" position={'relative'}>
          <Thead borderColor={'red'}>
            <Tr>
              <Th
                width={'50px'}
                px={'10px'}
                border="1px"
                borderColor="gray.200"
                color={tableHead}
                textAlign={'center'}
              >
                {t('Guruh kodi')}
              </Th>
              <Th
                px={'10px'}
                border="1px"
                borderColor="gray.200"
                color={tableHead}
              >
                {t('Ism')}
              </Th>
              <Th
                px={'10px'}
                border="1px"
                borderColor="gray.200"
                color={tableHead}
              >
                {t('Balans')}
              </Th>
              <Th
                px={'10px'}
                border="1px"
                borderColor="gray.200"
                color={tableHead}
              >
                {t('Viloyat')}
              </Th>
              <Th
                px={'10px'}
                border="1px"
                borderColor="gray.200"
                color={tableHead}
              >
                {t('Акт сверки')}
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {mainData.map((value, index) => {
              return (
                <Tr
                  cursor="pointer"
                  key={index}
                  onClick={() =>
                    navigation('viewRelocationSHOP', { state: value })
                  }
                  className="tr"
                  _hover={{
                    bg: bgTr,
                  }}
                >
                  <Td
                    width={'50px'}
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                    textAlign={'center'}
                  >
                    {get(value, 'groupCode', '')}
                  </Td>
                  <Td
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    {/* {time.to(get(value, 'docDate', ''), 'DD-MM-YYYY')} */}
                    {get(value, 'cardName', '')}
                  </Td>
                  <Td
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    {get(value, 'currentAccountBalance', '')}
                  </Td>
                  <Td
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    {get(value, 'region', '')}
                  </Td>

                  <Td
                    textAlign={'center'}
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    <Button mb={0}>{t('Акт сверки')}</Button>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>
    </Style>
  );
}
