import styled from 'styled-components';

const Style = styled.div`
  .iconCard {
    display: flex;
    align-items: center;
  }
  .text {
    margin-left: 20px;
  }
  .flex {
    display: flex;
    justify-content: flex-end;
  }
  .redcard {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: red;
  }
  .greenCard {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: green;
  }
`;

export default Style;
