import React, { useEffect, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  Table,
  Tbody,
  Thead,
  Text,
  Box,
  Td,
  Th,
  Tr,
  SimpleGrid,
  Input as MainInput,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery } from 'react-query';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';

export default function ViewAccountsPayableSHOP() {
  const { t } = useTranslation();
  const navigation = useNavigate();

  const { token } = useSelector((state) => state.main);

  const location = useLocation();
  const sendedData = get(location, 'state', {});
  const cardCode = get(sendedData, 'cardCode', '');

  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);

  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');

  const fetchOrders = async ({ queryKey }) => {
    const [_, startDate, endDate] = queryKey;

    let startUrl = `journal-entries/card-code/${cardCode}/`;
    let endUrl = `pagination/0`;
    let url = '';

    if (startDate.length > 0 && endDate.length > 0) {
      startUrl = startUrl + `start-date/${startDate}/end-date/${endDate}/`;
    }

    url = startUrl + endUrl;
    url = startUrl + endUrl;

    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['viewRelocationSHOP', startDate, endDate],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const [mainData, setMainData] = useState([]);
  console.log(mainData);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />
        <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
            gap="20px"
            mb="20px"
          >
            <MainInput
              isIconHas={false}
              value={`${t('Mijoz')} : ${get(sendedData, 'cardName', '')}`}
              borderRadius={'15px'}
              color={tablBody}
              disabled={true}
            />
            <MainInput
              value={`${t('Joriy balansi')} : ${get(
                sendedData,
                'currentAccountBalance',
                '',
              )}`}
              borderRadius={'15px'}
              color={tablBody}
              disabled={true}
            />
          </SimpleGrid>
          <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
            <Box display={'flex'}>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Boshlanish sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={startDate}
                  onChange={(v) => setStartDate(v.target.value)}
                />
              </div>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Tugash sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={endDate}
                  onChange={(v) => setEndDate(v.target.value)}
                />
              </div>
            </Box>
          </Box>
          <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
            {t('Malumotlarni yuklab olishni iloji bolmadi')}
          </Text>
          <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
            {t('Boshlanish va tugash sanalarini tanlang')}
          </Text>
        </Card>
      </Box>
    );
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <IconButton
        onClick={() => navigation(-1)}
        backgroundColor={'brand.200'}
        icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
        mb={'5px'}
      />
      <Card py="20px" border="1px" borderColor="gray.200">
        <SimpleGrid
          columns={{ base: 1, md: 2, lg: 3, '2xl': 6 }}
          gap="20px"
          mb="20px"
        >
          <MainInput
            isIconHas={false}
            value={`${t('Mijoz')} : ${get(sendedData, 'cardName', '')}`}
            borderRadius={'15px'}
            color={tablBody}
            disabled={true}
          />
          <MainInput
            value={`${t('Joriy balansi')} : ${get(
              sendedData,
              'currentAccountBalance',
              '',
            )}`}
            borderRadius={'15px'}
            color={tablBody}
            disabled={true}
          />
        </SimpleGrid>
        <Box mb={'20px'} display={'flex'} justifyContent={'space-between'}>
          <Box display={'flex'}>
            <div>
              <Text fontSize={14} ms={'5px'}>
                {t('Boshlanish sanasi')}
              </Text>
              <Input
                type={'date'}
                isIconHas={false}
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={startDate}
                onChange={(v) => setStartDate(v.target.value)}
              />
            </div>
            <div>
              <Text fontSize={14} ms={'5px'}>
                {t('Tugash sanasi')}
              </Text>
              <Input
                type={'date'}
                isIconHas={false}
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={endDate}
                onChange={(v) => setEndDate(v.target.value)}
              />
            </div>
          </Box>
        </Box>

        <Box overflow="auto">
          <Table variant={'unstyled'} color="gray.500" position={'relative'}>
            <Thead borderColor={'red'}>
              <Tr>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Sana')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                  textAlign={'center'}
                >
                  {t('Qisqa nomi')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Operatsiya nomi')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Debit')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Kredit')}
                </Th>
                <Th
                  px={'10px'}
                  border="1px"
                  borderColor="gray.200"
                  color={tableHead}
                >
                  {t('Camulative Balans')}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {mainData.map((value, index) => {
                return (
                  <Tr
                    key={index}
                    className="tr"
                    _hover={{
                      bg: bgTr,
                    }}
                  >
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {time.to(get(value, 'dueDate', ''), 'DD-MM-YYYY')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                      textAlign={'center'}
                    >
                      {get(value, 'shortName', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'lineMemo', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'debit', '')}
                    </Td>
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'credit', '')}
                    </Td>{' '}
                    <Td
                      border="1px"
                      borderColor="gray.200"
                      fontSize={{ sm: '14px' }}
                      minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                      px={'10px'}
                      py={'5px'}
                      color={tablBody}
                    >
                      {get(value, 'cumulativeBalance', '')}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </Card>
    </Box>
  );
}
