import styled from 'styled-components';

const Style = styled.div`
  .iconCard {
    display: flex;
    align-items: center;
  }
  .text {
    margin-left: 20px;
  }
  .flex {
    display: flex;
    justify-content:flex-end
  }
`;

export default Style;
