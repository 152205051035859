import React from 'react';
import {
  Box,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import CreditorsShop from './CreditorsShop';
import Card from 'components/card/Card';
import AccountsPayable from './AccountsPayable';

export default function PurchaseReportsSHOP() {
  const { t } = useTranslation();
  const tableHead = useColorModeValue('brand', 'white');

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card>
        <Tabs colorScheme={tableHead}>
          <TabList mb="20px">
            <Tab>{t('Barcha haridlar')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AccountsPayable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
}
