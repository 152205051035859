import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
  Text,
} from '@chakra-ui/react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AddIcon, ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import SuccessModal from 'components/Modals/SuccessModal';
import ProductModal from 'components/Modals/ProductModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Style from './Style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';
import AddIncomingPayment from '../../../components/Modals/AddIncomingPayment/index';
import { useQueryClient } from 'react-query';

export default function ViewTelegramOrderSHOP() {
  const { t } = useTranslation();
  const { token } = useSelector((state) => state.main);
  const queryClient = useQueryClient();

  const paymentRef = useRef();
  const errorRef = useRef();
  const successRef = useRef();
  const productRef = useRef();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  const redText = useColorModeValue('red', 'white');
  const greenText = useColorModeValue('green', 'white');

  const location = useLocation();
  const data = get(location, 'state', {});
  const docEntry = get(data, 'docEntry', '');
  const stockTransferLines = get(data, 'stockTransferLines', []);

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState(stockTransferLines);

  const createPayment = (date, user, acc, summa, currency,rate) => {
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .post(
        'incoming-payments/based-on-telegram-invoice',
        {
          docType: 'rCustomer',
          docDate: date,
          cardCode: get(user, 'cardCode', ''),
          cashAccount: acc,
          cashSum: summa,
          docCurrency: currency,
          docRate:rate,
          remarks: get(data, 'remarks', ''),
          id: get(data, 'id', ''),
        },
        header,
      )
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
        queryClient.invalidateQueries(['incomingPaymentSHOP']);
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <Box mb={'20px'}>
            <Box display={'flex'}>
              <Text color={tableHead} me={'5px'}>
                {t('Дата создания')}
                {':'}
              </Text>
              <Text>{time.to(get(data, 'createdAt', ''), 'DD-MM-YYYY')}</Text>
            </Box>
          </Box>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Ism')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Общая цена')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Valyuta')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('remarks')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    {get(data, 'cardName', '')}
                  </Td>
                  <Td
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    {get(data, 'cashSum', '')}
                  </Td>
                  <Td
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    {get(data, 'docCurrency', '')}
                  </Td>
                  <Td
                    border="1px"
                    borderColor="gray.200"
                    fontSize={{ sm: '14px' }}
                    minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                    px={'10px'}
                    py={'5px'}
                    color={tablBody}
                  >
                    {get(data, 'remarks', '')}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
          <Box justifyContent={'end'} display={'flex'} mt={'20px'}>
            <Button
              isLoading={isLoading}
              onClick={() => paymentRef.current?.open()}
              width={200}
              backgroundColor="green.500"
            >
              {t('Добавить входящий платеж')}
            </Button>
          </Box>
        </Card>
      </Box>
      <AddIncomingPayment
        getRef={(r) => (paymentRef.current = r)}
        isClientShow={true}
        isTypesShow={true}
        onConfirm={(date, user, acc, summa, currency,rate) =>
          createPayment(date, user, acc, summa, currency,rate)
        }
      />
      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} isBack={false} />
    </Style>
  );
}
