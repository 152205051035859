import React, { useEffect, useState, useRef } from 'react';
import {
  useColorModeValue,
  IconButton,
  Table,
  Tbody,
  Thead,
  Text,
  Box,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/Input';
import Style from './Style';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { useQuery, useQueryClient } from 'react-query';
import time from 'helpers/time';
import { get } from 'lodash';
import api from 'api';

export default function TelegramPaymentsSHOP() {
  const { t } = useTranslation();
  const navigation = useNavigate();
  const queryClient = useQueryClient();

  const errorRef = useRef();
  const successRef = useRef();

  const { token } = useSelector((state) => state.main);

  const [page, setPage] = useState(0);
  const [clientName, setClientName] = useState('');
  const [startDate, setStartDate] = useState(0);
  const [endDate, setEndDate] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const searchIconColor = useColorModeValue('white');
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const bgTr = useColorModeValue('gray.200', 'brand.200');
  const redText = useColorModeValue('red', 'white');
  const greenText = useColorModeValue('green', 'white');

  const fetchOrders = async ({ queryKey }) => {
    const [_, clientName, startDate, endDate, page] = queryKey;

    let startUrl = `incoming-payments/from-bots?card-name=${clientName}&PageSize=20&`;
    let endUrl = `PageIndex=${page + 1}`;
    let url = '';

    // if (clientName.length > 0) {
    //   startUrl = startUrl + `card-name=${clientName}&PageSize=10&`;
    // } else {
    //   startUrl = startUrl + 'PageSize=10&';
    // }

    url = startUrl + endUrl;

    const response = await api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  const { data, isFetching, isError } = useQuery(
    ['telegramPaymentsSHOP', clientName, startDate, endDate, page],
    fetchOrders,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    },
  );

  const createPayment = (date, user, acc, summa, currency) => {
    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .post(
        'incoming-payments/invoice-to-incoming-sequence',
        {
          docType: 'rCustomer',
          docDate: date,
          cardCode: get(user, 'cardCode', ''),
          cashAccount: acc,
          cashSum: summa,
          docCurrency: currency,
        },
        header,
      )
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
        queryClient.invalidateQueries(['telegramPaymentsSHOP']);
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const [mainData, setMainData] = useState([]);

  useEffect(() => {
    if (data !== undefined) {
      let resData = JSON.parse(data).data ? JSON.parse(data).data : [];
      setMainData(resData);
    }
  }, [data]);

  if (isError) {
    return (
      <Style>
        <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
          <Card py="20px" border="1px" borderColor="gray.200" overflow="auto">
            <Box mb={'20px'} className="flex">
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Nomi')}
                </Text>
                <Input
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  onChange={(e) => setClientName(e.target.value)}
                />
              </div>
              {/* <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Boshlanish sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={startDate}
                  onChange={(v) => setStartDate(v.target.value)}
                />
              </div>
              <div>
                <Text fontSize={14} ms={'5px'}>
                  {t('Tugash sanasi')}
                </Text>
                <Input
                  type={'date'}
                  isIconHas={false}
                  isLoading={isFetching}
                  width={'200px'}
                  borderRadius={'15px'}
                  me={'20px'}
                  p={0}
                  value={endDate}
                  onChange={(v) => setEndDate(v.target.value)}
                />
              </div> */}
              <Box width={'100'} display={'flex'} alignItems={'center'}>
                <IconButton
                  isLoading={isFetching}
                  me={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                  }
                  onClick={() => setPage((p) => p - 1)}
                  isDisabled={page < 1}
                />
                <Text>{page + 1}</Text>
                <IconButton
                  onClick={() => setPage((p) => p + 1)}
                  isLoading={isFetching}
                  ms={'10px'}
                  _hover={false}
                  backgroundColor={'brand.200'}
                  icon={
                    <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                  }
                />
              </Box>
            </Box>
            <Text fontSize={'30px'} textAlign={'center'} my={'6.5px'}>
              {t('Malumotlarni yuklab olishni iloji bolmadi')}
            </Text>
          </Card>
        </Box>
      </Style>
    );
  }

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <Card py="20px" border="1px" borderColor="gray.200">
          <Box mb={'20px'} className="flex">
            <div>
              <Text fontSize={14} ms={'5px'}>
                {t('Nomi')}
              </Text>
              <Input
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                onChange={(e) => setClientName(e.target.value)}
              />
            </div>
            {/* <div>
              <Text fontSize={14} ms={'5px'}>
                {t('Boshlanish sanasi')}
              </Text>
              <Input
                type={'date'}
                isIconHas={false}
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={startDate}
                onChange={(v) => setStartDate(v.target.value)}
              />
            </div>
            <div>
              <Text fontSize={14} ms={'5px'}>
                {t('Tugash sanasi')}
              </Text>
              <Input
                type={'date'}
                isIconHas={false}
                isLoading={isFetching}
                width={'200px'}
                borderRadius={'15px'}
                me={'20px'}
                p={0}
                value={endDate}
                onChange={(v) => setEndDate(v.target.value)}
              />
            </div> */}
            <Box width={'100'} display={'flex'} alignItems={'center'}>
              <IconButton
                isLoading={isFetching}
                me={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowLeftIcon color={searchIconColor} w="10px" h="10px" />
                }
                onClick={() => setPage((p) => p - 1)}
                isDisabled={page < 1}
              />
              <Text>{page + 1}</Text>
              <IconButton
                onClick={() => setPage((p) => p + 1)}
                isLoading={isFetching}
                ms={'10px'}
                _hover={false}
                backgroundColor={'brand.200'}
                icon={
                  <ArrowRightIcon color={searchIconColor} w="10px" h="10px" />
                }
              />
            </Box>
          </Box>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500" position={'relative'}>
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Ism')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Дата создания')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Общая цена')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Valyuta')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('remarks')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((value, index) => {
                  return (
                    <Tr
                      cursor="pointer"
                      key={index}
                      onClick={() =>
                        navigation('viewTelegramOrderSHOP', { state: value })
                      }
                      className="tr"
                      _hover={{
                        bg: bgTr,
                      }}
                    >
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'cardName', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {time.to(get(value, 'createdAt', ''), 'DD-MM-YYYY')}
                      </Td>

                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'cashSum', '')}
                      </Td>

                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'docCurrency', '')}
                      </Td>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        {get(value, 'remarks', '')}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

        </Card>

      </Box>
    </Style>
  );
}
