import React, { useRef, useState } from 'react';
import {
  useColorModeValue,
  IconButton,
  SimpleGrid,
  Table,
  Tbody,
  Thead,
  Input,
  Box,
  Td,
  Th,
  Tr,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { AddIcon, ArrowBackIcon, DeleteIcon } from '@chakra-ui/icons';
import WarehouseModal from 'components/Modals/WarehouseModal';
import SuccessModal from 'components/Modals/SuccessModal';
import ProductModal from 'components/Modals/ProductModal';
import ErrorModal from 'components/Modals/ErrorModal';
import Style from './Style';
import Button from '../../../components/Button';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Card from 'components/card/Card';
import { get } from 'lodash';
import api from 'api';
import ProductsInWarehouseModal from '../../../components/Modals/ProductsInWarehouseModal/index';

export default function CreateRequest() {
  const { t } = useTranslation();
  const { getMe, token } = useSelector((state) => state.main);
  const uWarehouse = get(getMe, 'uWarehouse', '');

  const errorRef = useRef();
  const successRef = useRef();
  const productRef = useRef();
  const warehouseRef = useRef();
  const productsInWhsRef = useRef();

  const navigation = useNavigate();
  const tableHead = useColorModeValue('brand.300', 'white');
  const tablBody = useColorModeValue('gray.600', 'white');
  const inputText = useColorModeValue('gray.700', 'gray.100');

  const [isLoading, setIsLoading] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [wData, setwData] = useState({
    warehouseCode: '',
    warehouseName: t('Ombor tanlang'),
  });

  const createNewRequest = () => {
    setIsLoading(true);
    const stockTransferLines = mainData.map((v, i) => {
      return {
        itemCode: get(v, 'itemCode', ''),
        quantity: Number(get(v, 'quantity', '')),
        fromWarehouseCode: get(wData, 'warehouseCode', ''),
      };
    });

    let allData = {
      isMoved: 'N',
      toWarehouse: uWarehouse,
      fromWarehouse: get(wData, 'warehouseCode', ''),
      stockTransferLines,
    };

    let header = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    api
      .post(`inventory-transfer-requests`, allData, header)
      .then(() => {
        setIsLoading(false);
        successRef.current?.open();
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  const addItem = (item) => {
    setMainData((pr) => [
      ...pr,
      {
        itemCode: get(item, 'itemCode', ''),
        itemName: get(item, 'itemName', ''),
        quantity: '',
      },
    ]);
  };

  const changeQuant = (i, quant) => {
    let changedData = [...mainData];
    changedData[i].quantity = quant;
    setMainData(changedData);
  };

  const remove = (i) => {
    let changedData = [...mainData];
    changedData.splice(i, 1);
    setMainData(changedData);
  };

  return (
    <Style>
      <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
        <IconButton
          onClick={() => navigation(-1)}
          backgroundColor={'brand.200'}
          icon={<ArrowBackIcon color={'white'} w="15px" h="15px" />}
          mb={'5px'}
        />

        <Card py="20px" border="1px" borderColor="gray.200">
          <SimpleGrid
            columns={{ base: 1, lg: 2, '2xl': 6 }}
            gap="20px"
            mb="20px"
            display={'flex'}
            justifyContent={'flex-end'}
          >
            <Button onClick={() => warehouseRef.current?.open()} minW={'200px'}>
              {get(wData, 'warehouseName', '')}
            </Button>
          </SimpleGrid>
          <Box overflow="auto">
            <Table variant={'unstyled'} color="gray.500">
              <Thead borderColor={'red'}>
                <Tr>
                  <Th
                    width={'50px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                    px={'10px'}
                  >
                    {t('Del')}
                  </Th>

                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Nomi')}
                  </Th>
                  <Th
                    px={'10px'}
                    border="1px"
                    borderColor="gray.200"
                    color={tableHead}
                  >
                    {t('Кол-во')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {mainData.map((v, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        width={'50px'}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        textAlign={'center'}
                      >
                        <IconButton
                          onClick={() => remove(index)}
                          _hover={false}
                          backgroundColor={'red'}
                          icon={
                            <DeleteIcon color={'white'} w="13px" h="13px" />
                          }
                        />
                      </Td>

                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                        onClick={() => {
                          productsInWhsRef.current?.open(
                            get(v, 'itemCode', ''),
                          );
                        }}
                      >
                        {get(v, 'itemName', '')}
                      </Td>
                      <Td
                        width={'100px'}
                        border="1px"
                        borderColor="gray.200"
                        fontSize={{ sm: '14px' }}
                        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                        px={'10px'}
                        py={'5px'}
                        color={tablBody}
                      >
                        <Input
                          type={'number'}
                          value={get(v, 'quantity', '')}
                          onChange={(title) =>
                            changeQuant(index, title.target.value)
                          }
                          color={inputText}
                        />
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </Box>

          <Box justifyContent={'space-between'} display={'flex'} mt={'20px'}>
            <IconButton
              onClick={() => productRef.current?.open()}
              _hover={false}
              backgroundColor={'brand.200'}
              icon={<AddIcon color={'white'} w="10px" h="10px" />}
            />
            <Button
              isLoading={isLoading}
              onClick={createNewRequest}
              width={200}
              backgroundColor="green.500"
            >
              {t('Yaratish')}
            </Button>
          </Box>
        </Card>
      </Box>

      <ErrorModal getRef={(r) => (errorRef.current = r)} />
      <SuccessModal getRef={(r) => (successRef.current = r)} />
      <ProductModal
        getRef={(r) => (productRef.current = r)}
        onConfirm={(item) => addItem(item)}
      />
      <ProductsInWarehouseModal
        getRef={(r) => (productsInWhsRef.current = r)}
      />
      <WarehouseModal
        getRef={(r) => (warehouseRef.current = r)}
        onConfirm={(item) => setwData(item)}
      />
    </Style>
  );
}
