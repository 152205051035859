import React from 'react';
import {
  useColorModeValue,
  Box,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Debtors from './Debtors';
import Card from 'components/card/Card';
import TotalSales from './TotalSales';

export default function SalesReportsSHOP() {
  const { t } = useTranslation();

  const tableHead = useColorModeValue('brand', 'white');

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <Card>
        <Tabs colorScheme={tableHead}>
          <TabList mb="20px">
            <Tab>{t('Qarzdorlar')}</Tab>
            <Tab>{t('Общие продажи')}</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Debtors />
            </TabPanel>
            <TabPanel>
              <TotalSales />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Card>
    </Box>
  );
}
