import React, { useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import logo from 'assets/img/auth/logo.jpg';
import { MdOutlineRemoveRedEye } from 'react-icons/md';
import ErrorModal from 'components/Modals/ErrorModal';
import { RiEyeCloseLine } from 'react-icons/ri';
import DefaultAuth from 'layouts/auth/Default';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { main } from 'store/slices';
import { get } from 'lodash';
import api from 'api';
import AuthStyle from './AuthStyle';

function SignIn() {
  const textColor = useColorModeValue('navy.700', 'white');
  const textColor3 = 'black';
  const textColorSecondary = 'gray.400';
  const brandStars = useColorModeValue('brand.500', 'brand.400');
  const [show, setShow] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [login, setLogin] = React.useState('jasurbek');
  const [password, setPassword] = React.useState('jasurbek123');
  const handleClick = () => setShow(!show);

  const errorRef = useRef();

  const { setMe, setToken, setJobTitle } = main.actions;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginProfile = () => {
    setIsLoading(true);
    const prod = {
      login,
      password,
    };

    api
      .post('/accounts/log-in', prod)
      .then((res) => {
        const token_data = JSON.parse(get(res, 'data', '{}'));
        const token = get(token_data, 'data.token', '');

        // api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        dispatch(setJobTitle(get(token_data, 'data.employee.jobTitle', '')));
        dispatch(setMe(get(token_data, 'data.employee', {})));

        dispatch(setToken(token));

        navigate('admin/default');
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        const errData = get(err.response, 'data', '{}');
        errorRef.current?.open(errData);
      });
  };

  return (
    <AuthStyle>
      <DefaultAuth illustrationBackground={logo} image={logo}>
        <Flex
          maxW={{ base: '100%', md: 'max-content' }}
          w="100%"
          mx={{ base: 'auto', lg: '0px' }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: '30px', md: '60px' }}
          px={{ base: '25px', md: '0px' }}
          mt={{ base: '40px', md: '14vh' }}
          flexDirection="column"
          bac
        >
          <Box me="auto">
            <Heading color={textColor} fontSize="36px" mb="10px">
              {t('Sign In')}
            </Heading>
            <Text
              mb="36px"
              ms="4px"
              color={textColorSecondary}
              fontWeight="400"
              fontSize="md"
            >
              {t('Kirish uchun login va parolni kiriting')}!
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: '100%', md: '420px' }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: 'auto', lg: 'unset' }}
            me="auto"
            mb={{ base: '20px', md: 'auto' }}
          >
            <FormControl>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                {t('Login')}
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <Input
                backgroundColor={'white'}
                value={login}
                onChange={(t) => setLogin(t.target.value)}
                isRequired={true}
                variant="auth"
                fontSize="sm"
                ms={{ base: '0px', md: '0px' }}
                type="email"
                placeholder={`${t('Login')}`}
                mb="24px"
                fontWeight="500"
                size="lg"
                textColor={textColor3}
              />
              <FormLabel
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                display="flex"
              >
                {t('Parol')}
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <InputGroup size="md" mb={'50px'}>
                <Input
                  backgroundColor={'white'}
                  value={password}
                  onChange={(t) => setPassword(t.target.value)}
                  isRequired={true}
                  fontSize="sm"
                  placeholder={`${t('Parol')}`}
                  mb="24px"
                  size="lg"
                  type={show ? 'text' : 'password'}
                  variant="auth"
                  textColor={textColor3}
                />
                <InputRightElement display="flex" alignItems="center" mt="4px">
                  <Icon
                    color={textColorSecondary}
                    _hover={{ cursor: 'pointer' }}
                    as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                    onClick={handleClick}
                  />
                </InputRightElement>
              </InputGroup>

              <Button
                isLoading={isLoading}
                loadingText={t('Yuklanmoqda')}
                _hover={false}
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="100%"
                h="50"
                onClick={loginProfile}
              >
                {t('Kirish')}
              </Button>
            </FormControl>
          </Flex>
        </Flex>

        <ErrorModal
          getRef={(r) => {
            errorRef.current = r;
          }}
        />
      </DefaultAuth>
    </AuthStyle>
  );
}

export default SignIn;
